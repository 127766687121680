#nprogress .bar {
    background: #29d;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px #29d, 0 0 5px #29d;
    opacity: 1.0;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
}

#nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-top-color: #29d;
    border-left-color: #29d;
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
    overflow: hidden;
    position: relative;
}

.nprogress-custom-parent #nprogress .spinner, .nprogress-custom-parent #nprogress .bar {
    position: absolute;
}

@-webkit-keyframes nprogress-spinner {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes nprogress-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

html {
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0
}

main {
    display: block
}

h1 {
    font-size: 2em;
    margin: 0.67em 0
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

pre {
    font-family: monospace, monospace;
    font-size: 1em
}

a {
    background-color: transparent;
    text-decoration: none
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted
}

b,strong {
    font-weight: bolder
}

code,kbd,samp {
    font-family: monospace, monospace;
    font-size: 1em;
    line-height: 1.15
}

small {
    font-size: 80%
}

sub,sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sub {
    bottom: -0.25em
}

sup {
    top: -0.5em
}

img {
    border-style: none
}

button,input,optgroup,select,textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0
}

button,input {
    overflow: visible
}

button,select {
    text-transform: none
}

button,[type="button"],[type="reset"],[type="submit"] {
    -webkit-appearance: button
}

button::-moz-focus-inner,[type="button"]::-moz-focus-inner,[type="reset"]::-moz-focus-inner,[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0
}

button:-moz-focusring,[type="button"]:-moz-focusring,[type="reset"]:-moz-focusring,[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText
}

fieldset {
    padding: 0.35em 0.75em 0.625em
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal
}

progress {
    vertical-align: baseline
}

textarea {
    overflow: auto
}

[type="checkbox"],[type="radio"] {
    box-sizing: border-box;
    padding: 0
}

[type="number"]::-webkit-inner-spin-button,[type="number"]::-webkit-outer-spin-button {
    height: auto;
    -webkit-appearance: none;
    margin: 0
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px
}

[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit
}

details {
    display: block
}

summary {
    display: list-item
}

template {
    display: none
}

[hidden] {
    display: none
}

@font-face {
    font-family: "proxima-nova";
    src: url(https://static.ambitionbox.com/_nuxt/Figtree-Bold.cbaa0ed.woff2) format("woff2"),url(https://static.ambitionbox.com/_nuxt/Figtree-Bold.20d002e.ttf) format("truetype");
    font-display: swap;
    font-style: normal;
    font-weight: 700
}

@font-face {
    font-family: "proxima-nova";
    src: url(https://static.ambitionbox.com/_nuxt/Figtree-SemiBold.777db59.woff2) format("woff2"),url(https://static.ambitionbox.com/_nuxt/Figtree-SemiBold.e7c36b4.ttf) format("truetype");
    font-display: swap;
    font-style: normal;
    font-weight: 600
}

@font-face {
    font-family: "proxima-nova";
    src: url(https://static.ambitionbox.com/_nuxt/Figtree-Medium.34c7111.woff2) format("woff2"),url(https://static.ambitionbox.com/_nuxt/Figtree-Medium.9f5bdea.ttf) format("truetype");
    font-display: swap;
    font-style: normal;
    font-weight: 500
}

@font-face {
    font-family: "proxima-nova";
    src: url(https://static.ambitionbox.com/_nuxt/Figtree-Regular.cd6f89b.woff2) format("woff2"),url(https://static.ambitionbox.com/_nuxt/Figtree-Regular.eb52c45.ttf) format("truetype");
    font-display: swap;
    font-style: normal;
    font-weight: 400
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.no-gutters,.no-row-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters>.col,.no-gutters>[class*="col-"] {
    padding-right: 0;
    padding-left: 0
}

.col-4,.col-6,.col-12,.col-sm-6,.col-sm-12,.col-md-3,.col-md-4,.col-md-6,.col-md-8,.col-md-12,.col-lg-4,.col-lg-12,.col-xl-4,.col-xl-5,.col-xl-7,.col-xl-8,.col-20,.col-3,.col-8,.col-2,.col-10 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px
}

.col-20 {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 25%
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%
}

.col-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%
}

.col-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%
}

.col-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%
}

@media (min-width: 576px) {
    .col-sm-6 {
        flex:0 0 50%;
        max-width: 50%
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media (min-width: 768px) {
    .col-md-3 {
        flex:0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media (min-width: 992px) {
    .col-20 {
        flex:0 0 20%;
        max-width: 20%
    }

    .col-lg-4 {
        flex: 0 0 33.333333%;
        max-width: 33.333333%
    }

    .col-lg-8 {
        flex: 0 0 66.666667%;
        max-width: 66.666667%
    }
}

.grid-wrapper .grid-row {
    display: flex;
    margin-left: -.9375rem;
    margin-right: -.9375rem;
    flex-wrap: wrap
}

.grid-wrapper .grid-row .col {
    box-sizing: border-box;
    margin: 0 .9375rem 1.875rem
}

.grid-wrapper .grid-row .col-50 {
    width: calc(50% - 30px)
}

.grid-wrapper .grid-row .col-50-xs {
    width: calc(50% - 30px)
}

.grid-wrapper .grid-row .col-100 {
    width: calc(100% - 30px)
}

@media only screen and (max-width: 575px) {
    .grid-wrapper .grid-row .col-50 {
        width:calc(100% - 30px)
    }
}

.grid-wrapper .grid-row-p15 {
    display: flex;
    margin-left: -.46875rem;
    margin-right: -.46875rem;
    flex-wrap: wrap
}

.grid-wrapper .grid-row-p15 .col-p15 {
    box-sizing: border-box;
    margin: 0 .46875rem 1.875rem
}

.grid-wrapper .grid-row-p15 .col-50-p15 {
    width: calc(50% - 15px)
}

.grid-wrapper .grid-row-p15 .col-100-p15 {
    width: calc(100% - 15px)
}

@media only screen and (max-width: 575px) {
    .grid-wrapper .grid-row-p15 .col-50-p15 {
        width:calc(50% - 15px)
    }
}

.grid-wrapper .grid-row-p12 {
    display: flex;
    margin-left: -.375rem;
    margin-right: -.375rem;
    flex-wrap: wrap
}

.grid-wrapper .grid-row-p12 .col-p12 {
    box-sizing: border-box;
    margin: 0 .375rem 1.875rem
}

.grid-wrapper .grid-row-p12 .col-50-p12 {
    width: calc(50% - 12px)
}

.grid-wrapper .grid-row-p12 .col-33-p12 {
    width: calc(33% - 12px)
}

.grid-wrapper .grid-row-p12 .col-25-p12 {
    width: calc(25% - 12px)
}

.grid-wrapper .grid-row-p12 .col-20-p12 {
    width: calc(20% - 12px)
}

.grid-wrapper .grid-row-p12 .col-100-p12 {
    width: calc(100% - 12px)
}

@media only screen and (max-width: 575px) {
    .grid-wrapper .grid-row-p12 .col-50-p12 {
        width:calc(50% - 12px)
    }
}

.bold,.bold-display-xxl,.bold-display-xl,.bold-display-l,.bold-display-m,.bold-display,.bold-title-l,.bold-title-m,.bold-title,.bold-title-s,.bold-section-header,.bold-list-header,.bold-Labels,.bold-normal {
    font-weight: bold;
    letter-spacing: normal;
    color: #1e223c
}

.semibold,.bold-header-desc,.sbold-display-xxl,.sbold-display-xl,.sbold-display-l,.sbold-display,.sbold-title-l,.sbold-title,.sbold-section-header,.sbold-list-header,.sbold-Labels {
    font-weight: 500;
    letter-spacing: normal;
    color: #1e223c
}

.body-text,.body-small,.body-small-l,.body-medium,.body-large {
    font-weight: normal;
    letter-spacing: normal;
    color: #1e223c
}

.caption-strong,.caption-strong-small,.caption-strong-medium,.caption-strong-large {
    font-weight: 500;
    letter-spacing: normal;
    color: #5e6b92
}

.caption-normal,.caption-normal-medium {
    font-weight: 400;
    letter-spacing: normal;
    color: #5e6b92
}

.caption-subdued,.caption-subdued-small,.caption-subdued-medium,.caption-subdued-large {
    font-weight: 500;
    letter-spacing: normal;
    color: #97a0be
}

.ctas,.ctas-a-small,.ctas-a-medium,.ctas-a-large,.ctas-btn-small,.ctas-btn-medium,.ctas-btn-large {
    font-weight: 600;
    line-height: 1.5;
    color: rgb(1, 80, 154);
    cursor: pointer;
    letter-spacing: normal
}

.ctas:hover,.ctas-a-small:hover,.ctas-a-medium:hover,.ctas-a-large:hover,.ctas-btn-small:hover,.ctas-btn-medium:hover,.ctas-btn-large:hover {
    color: #4158d4
}

.overline-text,.overline-text-small,.overline-text-medium,.overline-text-large {
    font-weight: 600;
    letter-spacing: 2px
}

.input-fields,.input-fields-placeholder-inactive,.input-fields-placeholder-focused {
    font-weight: 600;
    letter-spacing: normal
}

.badges,.badge-small,.badge-large,.badge-x-large {
    font-weight: 600;
    letter-spacing: normal
}

.mediumbold,.bold-display-sm,.mediumbold-medium,.mediumbold-normal {
    font-weight: 600;
    letter-spacing: normal;
    color: #1e223c
}

.bold-display-xxl {
    font-size: 2.5rem;
    line-height: 1.4
}

.bold-display-xl {
    font-size: 2.25rem;
    line-height: 1.44
}

.bold-display-l {
    font-size: 2rem;
    line-height: 1.5
}

.bold-display-m {
    font-size: 1.875rem;
    line-height: 1.33
}

.bold-display-sm {
    font-size: 1.875rem;
    line-height: 1.33
}

.bold-display {
    font-size: 1.75rem;
    line-height: 1.43
}

.bold-title-l {
    font-size: 1.5rem;
    line-height: 1.33
}

.bold-title-m {
    font-size: 1.375rem;
    line-height: 1.33
}

.bold-title {
    font-size: 1.25rem;
    line-height: 1.4
}

.bold-title-s {
    font-size: 1.125rem;
    line-height: 1.1
}

.bold-section-header {
    font-size: 1rem;
    line-height: 1.5
}

.bold-list-header {
    font-size: .875rem;
    line-height: 1.43
}

.bold-Labels {
    font-size: .75rem;
    line-height: 1.33
}

.bold-header-desc {
    font-size: .875rem;
    line-height: 1.43
}

.sbold-display-xxl {
    font-size: 2.5rem;
    line-height: 1.4
}

.sbold-display-xl {
    font-size: 2.25rem;
    line-height: 1.44
}

.sbold-display-l {
    font-size: 2rem;
    line-height: 1.5
}

.sbold-display {
    font-size: 1.75rem;
    line-height: 1.43
}

.sbold-title-l {
    font-size: 1.5rem;
    line-height: 1.33
}

.sbold-title {
    font-size: 1.25rem;
    line-height: 1.4
}

.sbold-section-header {
    font-size: 1rem;
    line-height: 1.5
}

.sbold-list-header {
    font-size: .875rem;
    line-height: 1.43
}

.sbold-Labels {
    font-size: .75rem;
    line-height: 1.33
}

.body-small {
    font-size: .75rem;
    line-height: 1.33
}

.body-small-l {
    font-size: .8125rem;
    line-height: 1.33
}

.body-medium {
    font-size: .875rem;
    line-height: 1.43
}

.body-large {
    font-size: 1rem;
    line-height: 1.5
}

.caption-strong-small {
    font-size: .625rem;
    line-height: 1.2
}

.caption-strong-medium {
    font-size: .75rem;
    line-height: 1.33
}

.caption-strong-large {
    font-size: .875rem;
    line-height: 1.43
}

.caption-normal-medium {
    font-size: .875rem;
    line-height: 1.25rem
}

.caption-subdued-small {
    font-size: .625rem;
    line-height: 1.2
}

.caption-subdued-medium {
    font-size: .75rem;
    line-height: 1.33
}

.caption-subdued-large {
    font-size: .875rem;
    line-height: 1.43
}

.ctas-a-small {
    font-size: .75rem;
    line-height: 1.33
}

.ctas-a-medium {
    font-size: .875rem;
    line-height: 1.43
}

.ctas-a-large {
    font-size: 1rem;
    line-height: 1.5
}

.ctas-btn-small {
    font-weight: bold;
    font-size: .75rem;
    line-height: 1.33
}

.ctas-btn-medium {
    font-weight: bold;
    font-size: .875rem;
    line-height: 1.43
}

.ctas-btn-large {
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5
}

.overline-text-small {
    font-size: .625rem;
    line-height: 1.2
}

.overline-text-medium {
    font-size: .75rem;
    line-height: 1.33
}

.overline-text-large {
    font-size: .875rem;
    line-height: 1.43
}

.input-fields-placeholder-inactive {
    font-weight: normal;
    font-size: .875rem;
    color: #97a0be;
    line-height: 1.43
}

.input-fields-placeholder-focused {
    font-weight: normal;
    font-size: .875rem;
    color: #1e223c;
    line-height: 1.43;
    text-transform: capitalize
}

.badge-small {
    font-size: .5rem
}

.badge-large {
    font-size: .625rem
}

.badge-x-large {
    font-size: 1.25rem
}

.mediumbold-medium {
    font-size: .875rem;
    line-height: 20px
}

.mediumbold-normal {
    font-size: 1rem;
    line-height: 1.375rem
}

.bold-normal {
    font-size: 1rem;
    line-height: 1.5rem
}

@font-face {
    font-family: "icomoon";
    src: url(https://static.ambitionbox.com/_nuxt/icomoon.2fea617.woff2) format("woff2"),url(https://static.ambitionbox.com/_nuxt/icomoon.321792d.woff) format("woff"),url(https://static.ambitionbox.com/_nuxt/icomoon.724b7a6.ttf) format("truetype"),url(https://static.ambitionbox.com/_nuxt/img/icomoon.d825e2e.svg#icomoon) format("svg"),url(https://static.ambitionbox.com/_nuxt/icomoon.53280ea.eot#iefix) format("embedded-opentype");
    font-weight: normal;
    font-style: normal;
    font-display: block
}

i {
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 1.25rem;
    color: #5e6b92;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon-terrible:before {
    content: "\e935";
    color: #ff4869
}

.icon-excellent:before {
    content: "\e928";
    color: #53c343
}

.icon-good:before {
    content: "\e92e";
    color: #71e34b
}

.icon-average:before {
    content: "\e92f";
    color: #faba2e
}

.icon-poor:before {
    content: "\e932";
    color: #fb733b
}

.icon-star-round-filled:before {
    content: "\e933";
    color: #faba2e
}

.icon-star-round:before {
    content: "\e934";
    color: #5e6b92
}

.icon-external-link .path1:before {
    content: "\e916";
    color: #fff
}

.icon-external-link .path2:before {
    content: "\e918"
}

.icon-vacancies:before {
    content: "\e915"
}

.icon-pin-outline:before {
    content: "\e912"
}

.icon-work:before {
    content: "\e913"
}

.icon-wallet:before {
    content: "\e914"
}

.icon-company-selectedsvg .path1:before {
    content: "\e95b";
    color: #c8d0f9
}

.icon-company-selectedsvg .path2:before {
    content: "\e95c";
    margin-left: -1.375em;
    color: rgb(1, 80, 154)
}

.icon-company-selectedsvg .path3:before {
    content: "\e95d";
    margin-left: -1.375em;
    color: #fff
}

.icon-company-selectedsvg .path4:before {
    content: "\e95e";
    margin-left: -1.375em;
    color: rgb(1, 80, 154)
}

.icon-company-selectedsvg .path5:before {
    content: "\e95f";
    margin-left: -1.375em;
    color: rgb(1, 80, 154)
}

.icon-company-selectedsvg .path6:before {
    content: "\e960";
    margin-left: -1.375em;
    color: rgb(1, 80, 154)
}

.icon-company .path1:before {
    content: "\e93d";
    color: #fff
}

.icon-company .path2:before {
    content: "\e93e";
    margin-left: -1.375em;
    color: #5e6b92
}

.icon-company .path3:before {
    content: "\e93f";
    margin-left: -1.375em;
    color: #fff
}

.icon-company .path4:before {
    content: "\e940";
    margin-left: -1.375em;
    color: #5e6b92
}

.icon-company .path5:before {
    content: "\e941";
    margin-left: -1.375em;
    color: #5e6b92
}

.icon-company .path6:before {
    content: "\e942";
    margin-left: -1.375em;
    color: #5e6b92
}

.icon-company .path7:before {
    content: "\e943";
    margin-left: -1.375em;
    color: #5e6b92
}

.icon-company .path8:before {
    content: "\e944";
    margin-left: -1.375em;
    color: #5e6b92
}

.icon-company .path9:before {
    content: "\e945";
    margin-left: -1.375em;
    color: #5e6b92
}

.icon-salary-selected .path1:before {
    content: "\e946";
    color: #c8d0f9
}

.icon-salary-selected .path2:before {
    content: "\e947";
    margin-left: -0.875em;
    color: rgb(1, 80, 154)
}

.icon-salary-selected .path3:before {
    content: "\e948";
    margin-left: -0.875em;
    color: #fff
}

.icon-salary-selected .path4:before {
    content: "\e949";
    margin-left: -0.875em;
    color: rgb(1, 80, 154)
}

.icon-salary-selected .path5:before {
    content: "\e94a";
    margin-left: -0.875em;
    color: rgb(1, 80, 154)
}

.icon-salary:before {
    content: "\e94b";
    color: #5e6b92
}

.icon-review-selected .path1:before {
    content: "\e94c";
    color: #c8d0f9
}

.icon-review-selected .path2:before {
    content: "\e94d";
    margin-left: -1em;
    color: rgb(1, 80, 154)
}

.icon-review-selected .path3:before {
    content: "\e94e";
    margin-left: -1em;
    color: rgb(1, 80, 154)
}

.icon-review-selected .path4:before {
    content: "\e94f";
    margin-left: -1em;
    color: rgb(1, 80, 154)
}

.icon-review:before {
    content: "\e950";
    color: #5e6b92
}

.icon-home-selected .path1:before {
    content: "\e951";
    color: rgb(1, 80, 154)
}

.icon-home-selected .path2:before {
    content: "\e952";
    margin-left: -1.375em;
    color: #ccd4fd
}

.icon-home-selected .path3:before {
    content: "\e953";
    margin-left: -1.375em;
    color: rgb(1, 80, 154)
}

.icon-home-selected .path4:before {
    content: "\e954";
    margin-left: -1.375em;
    color: #fff
}

.icon-home-selected .path5:before {
    content: "\e955";
    margin-left: -1.375em;
    color: rgb(1, 80, 154)
}

.icon-home .path1:before {
    content: "\e956";
    color: #5e6b92
}

.icon-home .path2:before {
    content: "\e957";
    margin-left: -1.375em;
    color: #fff
}

.icon-home .path3:before {
    content: "\e958";
    margin-left: -1.375em;
    color: #5e6b92
}

.icon-home .path4:before {
    content: "\e959";
    margin-left: -1.375em;
    color: #fff
}

.icon-home .path5:before {
    content: "\e95a";
    margin-left: -1.375em;
    color: #5e6b92
}

.icon-close:before {
    content: "\e931";
    color: #97a0be
}

.icon-share:before {
    content: "\e92b";
    color: #5e6b92
}

.icon-flag:before {
    content: "\e92c";
    color: #5e6b92
}

.icon-verified:before {
    content: "\e92d";
    color: #377e01
}

.icon-filter:before {
    content: "\e917";
    color: #5e6b92
}

.icon-tick:before {
    content: "\e926"
}

.icon-trust .path1:before {
    content: "\e929";
    color: rgb(1, 80, 154)
}

.icon-trust .path2:before {
    content: "\e92a";
    margin-left: -1em;
    color: #fff
}

.icon-google-plus-lite:before {
    content: "\e90e"
}

.icon-logout:before {
    content: "\e90f"
}

.icon-user-silhouette:before {
    content: "\e910"
}

.icon-linkedin:before {
    content: "\e901"
}

.icon-twitter:before {
    content: "\e902";
    color: #5e6b92
}

.icon-facebook:before {
    content: "\e900";
    color: #5e6b92
}

.icon-star:before {
    content: "\e90c"
}

.icon-access-time:before {
    content: "\e903";
    color: #5e6b92
}

.icon-chevron-right:before {
    content: "\e904";
    color: #5e6b92
}

.icon-company-placeholder .path1:before {
    content: "\e905";
    color: #f6f7ff
}

.icon-company-placeholder .path2:before {
    content: "\e906";
    margin-left: -1em;
    color: #5e6b92
}

.icon-company-placeholder .path3:before {
    content: "\e907";
    margin-left: -1em;
    color: #5e6b92
}

.icon-domain:before {
    content: "\e908";
    color: #5e6b92
}

.icon-pin-drop:before {
    content: "\e909";
    color: #5e6b92
}

.icon-search:before {
    content: "\e90a";
    color: #5e6b92
}

.icon-star-border:before {
    content: "\e90b";
    color: #5e6b92
}

.icon-supervisor-account:before {
    content: "\e90d";
    color: #5e6b92
}

.icon-google3:before {
    content: "\ea8a"
}

.icon-google:before {
    content: "\e930"
}

.icon-facebook-lite:before {
    content: "\ea90"
}

.icon-linkedin-lite:before {
    content: "\eaca"
}

.icon-star-half:before {
    content: "\e9d8"
}

.icon-arrow-right:before {
    content: "\e911"
}

.icon-stats-bars:before {
    content: "\e919"
}

.icon-interview:before {
    content: "\e91a";
    color: #5e6b92
}

.icon-delete:before {
    content: "\e91c";
    color: #5e6b92
}

.icon-edit:before {
    content: "\e91f";
    color: #5e6b92
}

.icon-dots-menu:before {
    content: "\e91b";
    color: #97a0be
}

.icon-bold:before {
    content: "\e920";
    color: #5e6b92
}

.icon-italic:before {
    content: "\e921";
    color: #5e6b92
}

.icon-underline:before {
    content: "\e922";
    color: #5e6b92
}

.icon-link:before {
    content: "\e923";
    color: #5e6b92
}

.icon-ordered-list:before {
    content: "\e924";
    color: #5e6b92
}

.icon-list:before {
    content: "\e925";
    color: #5e6b92
}

.icon-location:before {
    content: "\e927";
    color: #97a0be
}

.icon-tag:before {
    content: "\e936";
    color: #fff
}

.icon-copy:before {
    content: "\e937";
    color: #fff
}

.icon-center-align:before {
    content: "\e961";
    color: #5e6b92
}

.icon-code:before {
    content: "\e962";
    color: #5e6b92
}

.icon-left-align:before {
    content: "\e963";
    color: #5e6b92
}

.icon-info-i:before {
    content: "\e964";
    color: #959595
}

.icon-Star .path1:before {
    content: "\e967";
    color: #fff
}

.icon-Star .path2:before {
    content: "\e968";
    margin-left: -1em;
    color: #5e6b92
}

.icon-helpful:before {
    content: "\e965";
    color: #5b6ff2
}

.icon-helpful-solid:before {
    content: "\e966";
    color: #5b6ff2
}

.icon-helpful-grey:before {
    content: "\e969";
    color: #5e6b92
}

.icon-comment:before {
    content: "\e91d";
    color: #5e6b92
}

.icon-chat:before {
    content: "\e96a";
    color: #fff
}

.shadow-elevation-1 {
    box-shadow: 0 2px 6px -2px rgba(23,34,96,0.2);
    border: solid 1px #f5f7fd
}

.shadow-elevation-2 {
    box-shadow: 0 3px 8px 0 rgba(23,34,96,0.2);
    border: solid 1px #f5f7fd
}

.shadow-elevation-3 {
    box-shadow: 0 9px 16px 0 rgba(23,34,96,0.2);
    border: solid 1px #f5f7fd
}

.shadow-elevation-4 {
    box-shadow: 2px 6px 0 rgba(0,106,194,0.2)
}

.shadow-bottomsheet {
    box-shadow: 0 -4px 8px 0 rgba(23,34,96,0.2)
}

.shadow-left-curtain {
    box-shadow: 4px 0 8px 0 rgba(23,34,96,0.2)
}

.shadow-right-curtain {
    box-shadow: -4px 0 8px 0 rgba(23,34,96,0.2)
}

.shadow-section-bottom {
    box-shadow: inset 0 -1px 0 0 #e4e6ee
}

.shadow-section-top {
    box-shadow: inset 0 1px 0 0 #e4e6ee
}

.shadow-section-right {
    box-shadow: inset -1px 0 0 0 #e4e6ee
}

.container,.container-two-col {
    width: 100%;
    max-width: 64rem;
    margin: 0 auto
}

.container.maxWidthFix,.maxWidthFix.container-two-col {
    max-width: inherit;
    padding: 0
}

@media (max-width: 1024px) {
    .container,.container-two-col {
        margin:0;
        padding: 0;
    }
}

.container-two-col {
    display: flex
}

@media only screen and (max-width: 768px) {
    .container-two-col {
        display:block;
        max-width: 100%
    }
}

.container-two-col .col-left {
    flex: 0 0 65.625%;
    max-width: 65.625%;
    margin-right: 1.953125%
}

@media only screen and (max-width: 768px) {
    .container-two-col .col-left {
        flex:0 0 100%;
        max-width: 100%;
        margin-right: 0
    }
}

.container-two-col .col-right {
    flex: 0 0 32.421875%;
    max-width: 32.421875%
}

@media only screen and (max-width: 768px) {
    .container-two-col .col-right {
        flex:0 0 100%;
        max-width: 100%
    }
}

.block {
    display: block
}

.body-padding {
    padding-top: 3.375rem
}

.text-center {
    text-align: center
}

.text-right {
    text-align: right
}

.no-padd {
    padding: 0
}

.container-left {
    max-width: 64rem;
    margin: 0 auto;
    position: relative
}

@media (max-width: 1024px) {
    .container-left {
        margin:0;
        padding: 0 1rem;
        padding-right: 0
    }
}

.card-slider {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: auto !important;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none
}

.card-slider::-webkit-scrollbar {
    display: none
}

.card-slider>div,.card-slider>a {
    margin-right: 20px
}

@media only screen and (max-width: 768px) {
    .card-slider>div,.card-slider>a {
        margin-right:8px
    }
}

@media only screen and (max-width: 768px) {
    .set-min-width .card-slider>.ab_card {
        min-width:50vw
    }
}

@media only screen and (max-width: 575px) {
    .set-min-width .card-slider>.ab_card {
        min-width:80vw
    }
}

#salary-cards.card-slider {
    height: 11.25rem !important
}

@media only screen and (max-width: 575px) {
    #salary-cards.card-slider {
        height:10.625rem !important
    }
}

@media only screen and (max-width: 575px) {
    #contributeWidget.card-slider {
        margin-right:-16px;
        margin-bottom: 5px
    }
}

.shadow-buffer {
    padding-bottom: 4px
}

.hidden_control {
    display: none !important
}

.slider_next {
    right: -1rem
}

@media (max-width: 1100px) {
    .slider_next {
        right:0
    }
}

.slider_btns {
    width: 2rem;
    height: 2rem;
    position: absolute;
    opacity: 0.85;
    border-radius: 50%;
    top: calc(50% - 1rem);
    color: #757575;
    cursor: pointer;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 8px 0 rgba(0,106,194,0.2);
    background-color: #fff
}

@media only screen and (max-width: 768px) {
    .slider_btns {
        display:none
    }
}

.slider_btns:hover {
    opacity: 1;
    color: #222;
    border: solid 1px #ebf0f6;
    background-color: #fff
}

.slider_btns i {
    font-size: 1.5rem
}

.slider_prev {
    left: -1rem
}

@media (max-width: 1100px) {
    .slider_prev {
        left:0
    }
}

.slider_prev i,.slider_prev svg {
    transform: rotate(-180deg)
}

.flex-cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

@media only screen and (max-width: 768px) {
    .flex-cont {
        justify-content:space-between
    }
}

.flex-row {
    display: flex;
    flex-direction: row
}

.flex-col {
    display: flex;
    flex-direction: column
}

.flex-row-reverse-justify-center {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between
}

@media screen and (min-width: 767px) {
    .margin-bot-0-3n.visible:nth-last-child(-n+3),.margin-bot-0-3n:nth-last-child(-n+3) {
        margin-bottom:0px;
        padding-bottom: 0 !important
    }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
    .margin-bot-0-3n.visible:nth-last-child(-n+2),.margin-bot-0-3n:nth-last-child(-n+2) {
        margin-bottom:0;
        padding-bottom: 0 !important
    }
}

@media screen and (max-width: 576px) {
    .margin-bot-0-3n.visible:last-child,.margin-bot-0-3n:last-child {
        margin-bottom:0;
        padding-bottom: 0 !important
    }
}

@media screen and (min-width: 576px) {
    .margin-bot-0-2n:nth-last-child(-n+2),.margin-bot-0-2n.visible:nth-last-child(-n+2) {
        margin-bottom:0;
        padding-bottom: 0 !important
    }
}

@media screen and (max-width: 576px) {
    .margin-bot-0-2n:last-child,.margin-bot-0-2n.visible:last-child {
        margin-bottom:0;
        padding-bottom: 0 !important
    }
}

.footer-mg-null {
    padding-bottom: 2.5rem;
    margin-bottom: -3rem
}

#sticky-CTA .awards_title {
    font-size: .75rem;
    line-height: 1rem;
    letter-spacing: .01562rem;
    font-weight: 600;
    color: #eebc5c
}

#sticky-CTA .awards_trophy {
    width: 1rem;
    height: .875rem;
    font-size: .75rem;
    font-weight: 600;
    line-height: 1rem;
    letter-spacing: .01562rem;
    position: relative;
    top: 0;
    left: .125rem
}

.slide-right-enter,.slide-right-leave-to {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.slide-right-enter-to,.slide-right-leave {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.slide-right-enter-active,.slide-right-leave-active {
    transition: transform linear 100ms
}

.tray-enter {
    opacity: 0
}

.tray-enter-to {
    opacity: 1
}

.tray-enter-active {
    transition: opacity ease 500ms
}

.opacity-enter,.opacity-leave-to {
    opacity: 0
}

.opacity-enter-to,.opacity-leave {
    opacity: 1
}

.opacity-enter-active,.opacity-leave-active {
    transition: opacity linear 100ms
}

.bounce-top {
    -webkit-animation: bounce-top 0.6s ease-in-out;
    animation: bounce-top 0.6s ease-in-out
}

@-webkit-keyframes bounce-top {
    20% {
        -webkit-transform: rotateZ(-15deg) scale(1.25)
    }

    80% {
        -webkit-transform: rotateZ(15deg) scale(1.25)
    }

    0%,100% {
        -webkit-transform: rotateY(0deg) scale(1)
    }
}

@keyframes bounce-top {
    20% {
        transform: rotateZ(-15deg) scale(1.25)
    }

    80% {
        transform: rotateZ(15deg) scale(1.25)
    }

    0%,100% {
        transform: rotateY(0deg) scale(1)
    }
}

.shake {
    animation: shake 200ms 2 ease-in both;
    -webkit-animation: shake 200ms 2 ease-in both
}

@keyframes shake {
    0% {
        transform: translate(6px, 0)
    }

    50% {
        transform: translate(-6px, 0)
    }

    100% {
        transform: translate(0, 0)
    }
}

@-webkit-keyframes shake {
    0% {
        -webkit-transform: translate(6px, 0)
    }

    50% {
        -webkit-transform: translate(-6px, 0)
    }

    100% {
        -webkit-transform: translate(0, 0)
    }
}

@keyframes fade-in-out {
    0% {
        opacity: 0.5
    }

    25% {
        opacity: 1
    }

    85% {
        opacity: 0.75
    }

    100% {
        opacity: 0
    }
}

.fade-in {
    animation: fadeIn ease 5s;
    -webkit-animation: fadeIn ease 5s;
    -moz-animation: fadeIn ease 5s;
    -o-animation: fadeIn ease 5s;
    -ms-animation: fadeIn ease 5s
}

@media only screen and (min-width: 769px) {
    .fade-in {
        animation:fadeIn ease 2.5s;
        -webkit-animation: fadeIn ease 2.5s;
        -moz-animation: fadeIn ease 2.5s;
        -o-animation: fadeIn ease 2.5s;
        -ms-animation: fadeIn ease 2.5s
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0
    }

    100% {
        opacity: 1
    }
}

.fade-out {
    animation: fadeOut ease 0.5s;
    -webkit-animation: fadeOut ease 0.7s;
    -moz-animation: fadeOut ease 0.7s;
    -o-animation: fadeOut ease 0.7s;
    -ms-animation: fadeOut ease 0.7s
}

@keyframes fadeOut {
    0% {
        opacity: 0.8
    }

    100% {
        opacity: 0
    }
}

.rating-45 {
    background: #377e01
}

.rating-4 {
    background: #5ba829
}

.rating-35 {
    background: #9acd32
}

.rating-3 {
    background: #cdd614
}

.rating-25 {
    background: #ffba01
}

.rating-2 {
    background: #ff9a05
}

.rating-15 {
    background: #ff6e2b
}

.rating-1 {
    background: #ff4b2b
}

.rating-0 {
    background: #bfbfbf
}

.star-rating-45 {
    color: #377e01
}

.star-rating-4 {
    color: #5ba829
}

.star-rating-35 {
    color: #9acd32
}

.star-rating-3 {
    color: #cdd614
}

.star-rating-25 {
    color: #ffba01
}

.star-rating-2 {
    color: #ff9a05
}

.star-rating-15 {
    color: #ff6e2b
}

.star-rating-1 {
    color: #ff4b2b
}

.star-rating-0 {
    color: #bfbfbf
}

.v2-star-rating-45:before {
    color: #377e01
}

.v2-star-rating-4:before {
    color: #5ba829
}

.v2-star-rating-35:before {
    color: #9acd32
}

.v2-star-rating-3:before {
    color: #cdd614
}

.v2-star-rating-25:before {
    color: #ffba01
}

.v2-star-rating-2:before {
    color: #ff9a05
}

.v2-star-rating-15:before {
    color: #ff6e2b
}

.v2-star-rating-1:before {
    color: #ff4b2b
}

.v2-star-rating-0:before {
    color: #bfbfbf
}

.rating-45-brdr {
    border-left: .25rem solid #377e01
}

.rating-4-brdr {
    border-left: .25rem solid #5ba829
}

.rating-35-brdr {
    border-left: .25rem solid #9acd32
}

.rating-3-brdr {
    border-left: .25rem solid #cdd614
}

.rating-25-brdr {
    border-left: .25rem solid #ffba01
}

.rating-2-brdr {
    border-left: .25rem solid #ff9a05
}

.rating-15-brdr {
    border-left: .25rem solid #ff6e2b
}

.rating-1-brdr {
    border-left: .25rem solid #ff4b2b
}

.rating-0-brdr {
    border-left: .25rem solid #bfbfbf
}

.custom-checkbox {
    position: absolute;
    opacity: 0
}

.custom-checkbox+label {
    position: relative;
    cursor: pointer;
    padding: 0;
    font-size: .875rem;
    line-height: 1.43;
    color: #091e42;
    font-weight: normal;
    display: flex;
    align-items: flex-start
}

.custom-checkbox+label span {
    color: #97A0BE
}

.custom-checkbox+label div {
    margin-top: -.125rem
}

.custom-checkbox+label div.text-wrap {
    margin-top: 0
}

.custom-checkbox+label:before {
    content: '';
    margin-right: .625rem;
    display: inline-block;
    vertical-align: text-top;
    width: .9375rem;
    height: .9375rem;
    border: .0625rem solid #5E6B92;
    border-radius: 2px;
    background: #fff;
    transition: background-color 0.15s ease-in;
    margin-top: .0625rem;
    flex: none
}

@media only screen and (max-width: 768px) {
    .custom-checkbox+label:before {
        border-radius:.125rem
    }
}

.custom-checkbox:checked+label:before {
    background: rgb(1, 80, 154);
    border: .125rem solid rgb(1, 80, 154)
}

@media only screen and (max-width: 768px) {
    .custom-checkbox:checked+label:before {
        border-radius:.125rem;
        border: solid .0625rem rgb(1, 80, 154)
    }
}

.custom-checkbox:disabled+label {
    color: #b8b8b8;
    cursor: auto
}

.custom-checkbox:disabled+label:before {
    box-shadow: none;
    background: #ddd
}

.custom-checkbox:checked+label:after {
    content: '';
    position: absolute;
    left: .1875rem;
    top: .5rem;
    background: #fff;
    width: .125rem;
    height: .125rem;
    box-shadow: .0625rem 0 0 #fff,.1875rem 0 0 #fff,.1875rem -.125rem 0 #fff,.1875rem -.25rem 0 #fff,.1875rem -.375rem 0 #fff,.1875rem -.5rem 0 #fff;
    transform: rotate(45deg)
}

::-webkit-scrollbar {
    width: 5px;
    z-index: 1000;
    height: 5px;
    border-radius: 14px
}

::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    border-radius: 100px;
    background-color: #dfe6ed
}

::-webkit-scrollbar-track {
    background: transparent
}

@media only screen and (max-width: 768px) {
    .noscrollbars-sm {
        scrollbar-width:none
    }

    .noscrollbars-sm::-webkit-scrollbar {
        display: none
    }
}

@media only screen and (max-width: 768px) {
    .noscrollbars-sm {
        scrollbar-width:none
    }

    .noscrollbars-sm::-webkit-scrollbar {
        display: none
    }
}

@media only screen and (max-width: 768px) {
    .greyTablet {
        background-color:#f6f7ff
    }
}

.greyDesktopTablet {
    background-color: #f6f7ff
}

.whiteDesktop {
    background-color: #fff
}

.whiteDesktopGreyMobile {
    background-color: #fff
}

@media only screen and (max-width: 768px) {
    .whiteDesktopGreyMobile {
        background-color:#f6f7ff
    }
}

.transparentDesktop {
    background-color: transparent;
    transition: background-color .3s ease
}

@media only screen and (min-width: 769px) {
    .transparentDesktop .navbar,.transparentDesktop .nav-bar-wrapper {
        background:transparent !important
    }
}

.transparentDesktopWhiteMob {
    background-color: transparent;
    transition: background-color .3s ease
}

@media only screen and (max-width: 768px) {
    .transparentDesktopWhiteMob {
        transition:none;
        background-color: #fff
    }
}

.shadow {
    box-shadow: 0 2px 6px -2px rgba(0,106,194,0.2)
}

.noshadow {
    box-shadow: none !important
}

.floating-header {
    background-color: #fff !important;
    box-shadow: 0 2px 6px -2px rgba(0,106,194,0.2)
}

@media only screen and (min-width: 769px) {
    .scrollUpHeader {
        transform:translateY(-6.1875rem) !important
    }
}

@media only screen and (max-width: 768px) {
    .scrollUpHeader {
        transform:translateY(-3.375rem) !important
    }
}

#layoutTypeahed {
    background-color: #f6f7ff;
    z-index: 200;
    padding: 0 1.25rem
}

@media screen and (max-width: 1024px) {
    #layoutTypeahed {
        padding:0 1rem
    }
}

#layoutTypeahed.mobile {
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 205;
    overflow: hidden;
    transform: translateY(200vh);
    background-color: #f6f7ff
}

#layoutTypeahed.mobile.expanded {
    transform: none
}

#layoutHeaderWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
    transform: translateY(0);
    transition: transform 300ms ease-in,background-color 300ms ease;
    -webkit-transition: transform 300ms ease-in,background-color 300ms ease
}

#layoutHeaderWrapper.hide {
    display: none
}

.splScrn {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 9999;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none
}

.ng-logo-text {
    font-size: 1.75rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 800;
    color: #040a1e;
    letter-spacing: .193143px;
    text-align: center;
    margin: 0;
    line-height: 1
}

#circleG {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center
}

.circleG {
    background-color: #5570ff;
    float: left;
    height: 10px;
    margin: 5px;
    width: 10px;
    animation-name: bounce_circleG;
    -o-animation-name: bounce_circleG;
    -ms-animation-name: bounce_circleG;
    -webkit-animation-name: bounce_circleG;
    -moz-animation-name: bounce_circleG;
    animation-duration: 1.8s;
    -o-animation-duration: 1.8s;
    -ms-animation-duration: 1.8s;
    -webkit-animation-duration: 1.8s;
    -moz-animation-duration: 1.8s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-direction: normal;
    -o-animation-direction: normal;
    -ms-animation-direction: normal;
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;
    border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%
}

#circleG_1 {
    animation-delay: .45s;
    -o-animation-delay: .45s;
    -ms-animation-delay: .45s;
    -webkit-animation-delay: .45s;
    -moz-animation-delay: .45s
}

#circleG_2 {
    animation-delay: .65s;
    -o-animation-delay: .65s;
    -ms-animation-delay: .65s;
    -webkit-animation-delay: .65s;
    -moz-animation-delay: .65s
}

#circleG_3 {
    animation-delay: .85s;
    -o-animation-delay: .85s;
    -ms-animation-delay: .85s;
    -webkit-animation-delay: .85s;
    -moz-animation-delay: .85s
}

#circleG_4 {
    animation-delay: 1.05s;
    -o-animation-delay: 1.05s;
    -ms-animation-delay: 1.05s;
    -webkit-animation-delay: 1.05s;
    -moz-animation-delay: 1.05s
}

#circleG_5 {
    animation-delay: 1.25s;
    -o-animation-delay: 1.25s;
    -ms-animation-delay: 1.25s;
    -webkit-animation-delay: 1.25s;
    -moz-animation-delay: 1.25s
}

@keyframes bounce_circleG {
    0%,50%,100% {
        transform: scale(1);
        opacity: 0.5
    }

    25% {
        transform: scale(0.6);
        opacity: 0.75
    }

    75% {
        transform: scale(1.4);
        opacity: 1
    }
}

html,body {
    font-family: 'proxima-nova', Arial, sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-tap-highlight-color: transparent
}

*,*:before,*:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0
}

#nprogress .bar {
    top: 0;
    background: rgb(1, 80, 154);
    height: 3px !important
}

#nprogress .peg {
    box-shadow: none
}

.section-margin-bottom {
    margin-bottom: 2rem
}

@media only screen and (max-width: 768px) {
    .section-margin-bottom {
        margin-bottom:1.75rem
    }
}

.mg-tb-38 {
    margin-top: 2.5rem
}

.no-mg-top {
    margin-top: 0
}

.no-mg-btm {
    margin-bottom: 0
}

.mg-btm-40 {
    margin-bottom: 2.5rem !important
}

.mg-btm-4 {
    margin-bottom: .25rem !important
}

.mg-btm-36 {
    margin-bottom: 2.25rem !important
}

.mg-btm-20 {
    margin-bottom: 1.25rem !important
}

.mg-btm-16 {
    margin-bottom: 1rem
}

.mg-tp-16 {
    margin-top: 1rem
}

.mg-btm-12 {
    margin-bottom: .75rem
}

.mg-btm-24 {
    margin-bottom: 1.5rem !important
}

.mg-btm-28 {
    margin-bottom: 1.75rem !important
}

.mg-btm-10 {
    margin-bottom: .625rem
}

.mg-btm-0 {
    margin-bottom: 0rem !important
}

.mg-btm-8 {
    margin-bottom: .5rem !important
}

.mg-btm-30 {
    margin-bottom: 1.875rem
}

.mg-btm-32 {
    margin-bottom: 2rem
}

.mg-btm-43 {
    margin-bottom: 2.6875rem
}

.mg-top-4 {
    margin-top: .25rem
}

.mg-top-8 {
    margin-top: .5rem
}

.mg-top-12 {
    margin-top: .75rem
}

.mg-top-14 {
    margin-top: .875rem
}

.mg-top-16 {
    margin-top: 1rem
}

.mg-top-20 {
    margin-top: 1.25rem
}

.mg-top-24 {
    margin-top: 1.5rem
}

.mg-btm-29-5 {
    margin-bottom: 1.84375rem !important
}

.mg-top-30 {
    margin-top: 1.875rem
}

.mg-top-32 {
    margin-top: 2rem
}

.mg-top-36 {
    margin-top: 2.25rem
}

.mg-top-40 {
    margin-top: 2.5rem
}

.mg-top-46 {
    margin-top: 2.875rem
}

.mg-top-48 {
    margin-top: 3rem
}

.pd-top-0 {
    padding-top: 0 !important
}

.pd-btm-29-5 {
    padding-bottom: 1.84375rem !important
}

.pd-btm-32 {
    padding-bottom: 2rem !important
}

.pd-btm-39 {
    padding-bottom: 2.4375rem !important
}

.pd-btm-48 {
    padding-bottom: 3rem
}

.pd-top-44 {
    padding-top: 2.75rem
}

.pd-tp-48 {
    padding-top: 3rem
}

.pd-top-4rem {
    padding-top: 4rem
}

.pd-tp-32 {
    padding-top: 2rem
}

.mg-top-16 {
    margin-top: 1rem
}

.mg-top-20 {
    margin-top: 1.25rem
}

.mg-top-22 {
    margin-top: 1.375rem
}

.mg-top-25 {
    margin-top: 1.5625rem
}

.mg-top-24 {
    margin-top: 1.5rem
}

.mg-top-28 {
    margin-top: 1.75rem
}

.mg-rt-4 {
    margin-right: .25rem
}

.mg-rt-8 {
    margin-right: .5rem
}

.mg-rt-32 {
    margin-right: 2rem
}

.mg-rt-10 {
    margin-right: .625rem
}

.mg-lt-4 {
    margin-left: .25rem
}

.capitalize {
    text-transform: capitalize
}

.heading-margin-bottom {
    margin-bottom: 1rem
}

@media only screen and (max-width: 768px) {
    .heading-margin-bottom {
        margin-bottom:.875rem
    }
}

.p-0 {
    padding: 0px !important
}

.pl-0 {
    padding-left: 0px !important
}

.p-6 {
    padding: .375rem
}

.p-12 {
    padding: .75rem
}

.no-mrg {
    margin: 0
}

input[type='search']::-webkit-search-decoration,input[type='search']::-webkit-search-cancel-button,input[type='search']::-webkit-search-results-button,input[type='search']::-webkit-search-results-decoration {
    display: none
}

.badge-purple {
    padding: 2px 8px;
    display: inline-block;
    border-radius: 12px;
    color: #2a47de;
    font-size: .625rem;
    font-weight: 600;
    line-height: 1.2;
    background-color: #e7eaff
}

.live-badge {
    width: 2.625rem;
    height: 1.125rem;
    font-size: .6875rem;
    font-weight: 600;
    line-height: 1.09;
    letter-spacing: normal;
    text-align: right;
    color: #fff;
    border-radius: .125rem;
    background-color: #ff6f30;
    display: inline-flex;
    align-items: center;
    padding: .3125rem .375rem .25rem .375rem;
    vertical-align: text-top;
    margin-left: .1875rem
}

.live-badge span {
    width: .25rem;
    height: .25rem;
    background-color: #fff;
    margin-left: .1875rem;
    border-radius: 50%
}

.badge-orange {
    padding: .075rem .4rem;
    border-radius: .5625rem;
    background-color: #ff6e2b;
    color: #ffffff;
    font-size: 0.6875rem;
    font-weight: 500;
    line-height: normal;
    display: inline-block;
    vertical-align: middle;
    height: 1rem;
    margin-left: .1875rem;
    box-sizing: border-box
}

.badge-orange.awards {
    padding: .0625rem .4375rem;
    background-color: #FFECCF;
    color: #823D00;
    font-size: .5625rem;
    font-weight: 700;
    border-radius: 3px;
    line-height: .875rem;
    margin-left: .375rem;
    height: fit-content
}

.hide {
    display: none
}

.show {
    display: block
}

.show-flex {
    display: flex
}

.filter-by {
    display: flex;
    align-items: center;
    color: #5E6B92;
    font-size: .875rem;
    margin-right: .75rem;
    white-space: nowrap
}

.filter-by img {
    margin-right: .25rem
}

.icon-star,.icon-star-border {
    font-size: 1.1875rem
}

.icon-star-half {
    font-size: 1rem
}

.visible-sm {
    display: none
}

@media only screen and (max-width: 768px) {
    .visible-sm {
        display:block
    }
}

.hidden-sm {
    display: block
}

@media only screen and (max-width: 768px) {
    .hidden-sm {
        display:none
    }
}

.read-more {
    font-weight: bold;
    letter-spacing: normal;
    font-weight: 600;
    cursor: pointer
}

.read-more:hover {
    color: #4158d4
}

.page-enter-active,.page-leave-active {
    transition: opacity 0.5s
}

.page-enter,.page-leave-to {
    opacity: 0
}

.vue-notification-group {
    z-index: 9999999 !important;
    top: 30px !important
}

.vue-notification-group.shiftDown {
    top: calc(56px + 30px) !important
}

:invalid {
    box-shadow: none
}

:-moz-submit-invalid {
    box-shadow: none
}

:-moz-ui-invalid {
    box-shadow: none
}

.layout-enter-active,.layout-leave-active {
    transition: opacity 0.2s
}

.layout-enter,.layout-leave-active {
    opacity: 0
}

.text-capitalize {
    text-transform: capitalize
}

.text-right {
    text-align: right
}

.inline {
    display: inline-block
}

.loading,#company-ad-full-w:empty,#company-ad-full-w>div:empty,#company-ad-small:empty,#company-ad-small-2:empty,#company-ad-small>div:empty,#company-ad-small-2>div:empty,#company-ad-column:empty,#company-ad-column>div:empty {
    position: relative;
    overflow: hidden
}

.loading::after,#company-ad-full-w:empty::after,#company-ad-full-w>div:empty::after,#company-ad-small:empty::after,#company-ad-small-2:empty::after,#company-ad-small>div:empty::after,#company-ad-small-2>div:empty::after,#company-ad-column:empty::after,#company-ad-column>div:empty::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, #f4f4f4 0px, rgba(229,229,229,0.8) 40px, #f4f4f4 80px);
    background-size: 600px;
    animation: shine-lines 1s infinite ease-out
}

.line {
    height: 14px;
    border-radius: 7px
}

@keyframes shine-lines {
    0% {
        transform: translateX(-100px)
    }

    40%,100% {
        transform: translateX(0)
    }
}

@keyframes shine-avatar {
    0% {
        background-position: -32px
    }

    40%,100% {
        background-position: 208px
    }
}

@keyframes move {
    to {
        transform: translateX(calc(50% + 100px))
    }
}

.badge-icon {
    background-color: #e59c05;
    font-size: .625rem;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    border-radius: 50%;
    padding: 2px 6px;
    margin: auto 5px auto 0px
}

.stickFilter {
    top: 53px;
    left: 0;
    right: 0;
    background-color: #fff;
    z-index: 100;
    transition: top 300ms ease-in;
    -webkit-transition: top 300ms ease-in
}

.stickFilter>*:last-child {
    position: relative
}

.stickFilter>*:last-child::after {
    position: absolute;
    content: '';
    right: -1rem;
    width: 1rem;
    height: 100%
}

.stickFilterSlideUp {
    position: fixed;
    box-shadow: 0 2px 6px -2px rgba(0,106,194,0.2);
    top: 0px
}

.stickFilterSlideUpReview {
    position: fixed;
    box-shadow: 0 2px 6px -2px rgba(0,106,194,0.2);
    top: 106px;
    border-top: solid 1px #ebf0f6
}

.stickFilterSlideDown {
    position: fixed;
    box-shadow: 0 2px 6px -2px rgba(0,106,194,0.2);
    top: 99px;
    border-top: solid 1px #ebf0f6
}

@media only screen and (max-width: 768px) {
    .stickFilterSlideDown {
        top:53px
    }
}

main.card-arch {
    padding-top: 1.75rem;
    border-radius: 1.5rem 1.5rem 0 0;
    box-shadow: 0 -4px 6px -2px rgba(0,106,194,0.09);
    margin-top: -1.5rem;
    overflow: hidden;
    background: #fff;
    border-top: solid 1px #ebf0f6
}

.stickFiltersUp {
    position: fixed;
    box-shadow: 0 2px 6px -2px rgba(0,106,194,0.2);
    top: 3.375rem;
    background-color: #fff;
    transition: top 300ms ease-in;
    z-index: 99;
    translate: top;
    width: 100%
}

.stickFiltersDown {
    position: fixed;
    box-shadow: 0 2px 6px -2px rgba(0,106,194,0.2);
    top: 6.625rem;
    background-color: #fff;
    transition: top 300ms ease-in;
    z-index: 99;
    width: 100%
}

.stickTabsUp {
    width: 100%;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    left: 0;
    z-index: 100;
    transition: top 300ms ease-in
}

.stickTabsUp.shiftDown {
    top: 3.5rem
}

.stickTabsUpFilter {
    width: 100%;
    position: sticky;
    position: -webkit-sticky;
    top: 54px;
    left: 0;
    z-index: 99;
    transition: top 300ms ease-in
}

.stickTabsUpFilter.shiftDown {
    top: calc(54px + 56px)
}

.stickTabsUpFilter.shadow {
    box-shadow: 0 2px 6px -2px rgba(0,106,194,0.2)
}

.stickTabsDown {
    width: 100%;
    position: fixed;
    left: 0;
    z-index: 100;
    transition: top 300ms ease-in;
    top: 8rem
}

@media only screen and (max-width: 768px) {
    .stickTabsDown {
        top:3.25rem
    }
}

.new-suggestion {
    color: #5770fc;
    font-size: .875rem;
    line-height: 1.0625rem;
    margin-bottom: 1rem;
    cursor: pointer
}

.question-badge {
    background-color: #97A0BE;
    color: #fff;
    cursor: default;
    height: .75rem;
    width: .75rem;
    border-radius: 50%;
    text-align: center;
    font-size: .625rem;
    line-height: .78125rem;
    display: flex;
    justify-content: center;
    align-items: center
}

.ab_seo_interlinking {
    margin-top: 1.75rem
}

@media only screen and (max-width: 768px) {
    .ab_seo_interlinking {
        margin-top:1.25rem
    }
}

.red-dot {
    position: absolute;
    height: .375rem;
    width: .375rem;
    background-color: #e23030;
    border-radius: 50%;
    top: 0rem;
    right: -0.5rem
}

.round-loader {
    border: 0.25rem solid #f4f4f4;
    border-radius: 50%;
    border-top: 0.25rem solid #97A0BE;
    width: 2rem;
    height: 2rem;
    margin: 1.5rem auto 1rem;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite
}

.round-loader.small {
    margin: 0.125rem auto -0.75rem;
    width: 1.5rem;
    height: 1.5rem;
    border-width: .1875rem
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg)
    }
}

.salary_card_faqs {
    margin-top: 1.75rem
}

@media only screen and (max-width: 768px) {
    .salary_card_faqs {
        margin:1.25rem -1rem 0 -1rem
    }
}

.error-color {
    color: #ff4869
}

.medium-space {
    margin-top: 2.5rem
}

.large-space {
    margin-top: 3.4rem
}

.cursor-pointer {
    cursor: pointer
}

.cursor-auto {
    cursor: auto
}

.visibility-hidden {
    visibility: hidden
}

.word-break {
    word-break: break-word
}

.white-space-no-wrap {
    white-space: nowrap
}

.overflow-wrap {
    overflow-wrap: break-word
}

@media only screen and (min-width: 769px) {
    .pd-tp-99 {
        padding-top:6.1875rem
    }
}

.z-index-88 {
    z-index: 88 !important
}

.overflow-x-auto {
    overflow-x: auto
}

.gradient {
    background: linear-gradient(98.7deg, #fff5e3 -19.14%, #da9949 85.23%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent
}

.w-100 {
    width: 100%
}

.g-flex {
    display: flex
}

.g-flex-col {
    display: flex;
    flex-direction: column
}

.g-flex-v-center {
    display: flex;
    align-items: center
}

.g-flex-v-end {
    display: flex;
    align-items: flex-end
}

.g-flex-h-center {
    display: flex;
    justify-content: center
}

.g-flex-hv-center {
    display: flex;
    justify-content: center;
    align-items: center
}

.g-flex-h-apart {
    display: flex;
    justify-content: space-between
}

.g-flex-col-apart {
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.g-flex-wrap {
    flex-wrap: wrap
}

.g-pos-rel {
    position: relative
}

.g-pos-abs {
    position: absolute
}

.g-unstyled-list {
    list-style: none
}

.naukri-js-wdgt {
    margin: 0 !important;
    padding: 0 !important;
    border-radius: unset !important;
    box-shadow: unset !important;
    min-height: 0 !important;
    overflow: visible !important
}

#company-ad-full-w,#company-ad-full-w>div {
    min-height: 9.5rem
}

#company-ad-small,#company-ad-small-2,#company-ad-small>div,#company-ad-small-2>div {
    min-height: 11.5rem
}

#company-ad-column,#company-ad-column>div {
    min-height: 12.25rem
}

.loader-anim {
    pointer-events: none;
    background-image: linear-gradient(90deg, #f4f4f4 0px, rgba(229,229,229,0.8) 40px, #f4f4f4 80px);
    background-size: 600px;
    position: relative;
    overflow: hidden
}

.loader-anim::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: calc(100% + 100px);
    bottom: 0;
    background-image: inherit;
    animation: move 1s linear infinite
}

.pb-80 {
    padding-bottom: 5rem !important
}

.mb-56 {
    margin-bottom: 3.5rem !important
}

.no-opacity {
    opacity: 0
}

.obj-contain {
    object-fit: contain
}

.fixed-typeahead-wrapper {
    padding-top: 6.1875rem;
    background-color: #f6f7ff
}

.fixed-typeahead-wrapper .ab_typeahead2 .subtitle {
    font-size: 0.875rem
}

@media only screen and (max-width: 768px) {
    .fixed-typeahead-wrapper {
        padding-bottom:0;
        margin-bottom: 0;
        padding-top: 3.375rem
    }
}

@media only screen and (min-width: 769px) {
    .fixed-typeahead-wrapper.showAwardsBanner {
        padding-top:10.1875rem
    }
}

@media only screen and (min-width: 769px) {
    .layout-content.showAwardsBanner {
        padding-top:10.1875rem
    }
}

.white-icon {
    filter: invert(100%) saturate(5%) brightness(180%) contrast(100%)
}

.white-outline {
    border: 0.0625rem solid white !important;
    color: white !important
}

.white-bg {
    background-color: #fff !important
}

.white-txt {
    color: #fff !important
}

.white-txt::before {
    color: #fff
}

.cont {
    height: 3.4375rem;
    margin-top: 1.25rem
}

.cont:hover .contributeDropdown {
    display: block
}

.prpl-btn-crv {
    cursor: pointer;
    color: #fff;
    padding: .5625rem .875rem;
    background: #4769f6;
    border-radius: 3.125rem;
    margin-right: 1.25rem;
    width: 7.375rem;
    display: flex;
    align-items: center;
    height: 2rem;
    justify-content: center
}

.prpl-btn-crv.invert {
    background: #fff;
    color: #1E223C
}

.ab_header {
    width: 100%
}

.ab_header.hide {
    display: none
}

@media only screen and (max-width: 768px) {
    .ab_header {
        padding:0px;
        z-index: 100
    }
}

.ab_header .container {
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center
}

.ab_header .navbar {
    height: 3.75rem;
    display: flex;
    justify-content: space-between;
    padding: 0px 1.875rem;
    width: 100vw
}

.ab_header .navbar .typeahead-wrapper3 {
    padding: 0px !important
}

@media only screen and (max-width: 768px) {
    .ab_header .navbar {
        height:3.5rem;
        padding: 0px 0rem 0rem .5rem;
        margin-bottom: 0px;
        box-shadow: 0px 2px 16px rgba(0,0,0,0.1);
        background-color: #fff
    }
}

@media only screen and (min-width: 769px) {
    .ab_header .navbar {
        background:#fff
    }
}

@media only screen and (max-width: 768px) {
    .ab_header .navbar.invert {
        background-color:transparent;
        padding: 0px .5rem
    }
}

.ab_header .navbar.invert .border-right::after {
    opacity: 0.1
}

@media only screen and (max-width: 575px) {
    .ab_header .right-container {
        width:70%;
        justify-content: end !important
    }
}

.ab_header .plus {
    font-size: 1.375rem;
    margin-right: .5625rem
}

.ab_header .logo {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: .625rem;
    margin-right: 2.5rem;
    margin-top: -.1875rem
}

@media only screen and (max-width: 768px) {
    .ab_header .logo {
        margin-right:0;
        margin-top: 0;
        padding-left: .25rem
    }
}

.ab_header .logo img {
    object-fit: contain;
    height: 1.875rem
}

@media only screen and (max-width: 768px) {
    .ab_header .logo img {
        height:1.25rem
    }
}

.ab_header .logo .text {
    font-size: 1.2875rem;
    font-weight: bold;
    line-height: normal;
    letter-spacing: .02187rem;
    line-height: 1.22;
    margin-left: .5rem
}

@media only screen and (max-width: 768px) {
    .ab_header .logo .text {
        font-size:1rem;
        line-height: .875rem;
        letter-spacing: 0.193143px;
        margin-left: .25rem
    }
}

.ab_header .right-container,.ab_header .left-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.ab_header .left-container .visible-xs {
    display: none
}

@media only screen and (max-width: 768px) {
    .ab_header .left-container .visible-xs {
        display:inline-flex
    }
}

@media only screen and (max-width: 575px) {
    .ab_header .left-container {
        width:100%;
        justify-content: flex-end
    }
}

.ab_header .nav-list-right,.ab_header .nav-list-left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center
}

.ab_header .nav-list-right .extra-badge-margin,.ab_header .nav-list-left .extra-badge-margin {
    margin-right: .3125rem
}

.ab_header .nav-list-right .list-item,.ab_header .nav-list-left .list-item {
    display: inline-flex
}

@media only screen and (max-width: 768px) {
    .ab_header .nav-list-right .list-item,.ab_header .nav-list-left .list-item {
        display:none
    }
}

.ab_header .nav-list-right .list-item.visible-xs,.ab_header .nav-list-left .list-item.visible-xs {
    display: none
}

@media only screen and (max-width: 768px) {
    .ab_header .nav-list-right .list-item.visible-xs,.ab_header .nav-list-left .list-item.visible-xs {
        display:inline-flex
    }
}

.ab_header .nav-list-right .list-item.hide,.ab_header .nav-list-left .list-item.hide {
    display: none !important
}

.ab_header .nav-list-right .badge-common,.ab_header .nav-list-right .notification-menu .badge,.ab_header .nav-list-left .notification-menu .nav-list-right .badge,.ab_header .nav-list-left .badge-common,.ab_header .nav-list-right .notification-menu .nav-list-left .badge,.ab_header .nav-list-left .notification-menu .badge {
    position: absolute;
    background: #e23030;
    border-radius: .375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1rem;
    height: 0.83rem;
    font-style: normal;
    font-weight: 600;
    font-size: 0.7rem;
    line-height: .625rem;
    color: #fff
}

.ab_header .nav-list-right .notification-menu,.ab_header .nav-list-left .notification-menu {
    text-decoration: none;
    padding-right: 0.4375rem !important;
    padding-left: 0.4375rem !important;
    margin-right: .8125rem;
    display: inline-flex;
    align-self: center;
    cursor: pointer;
    position: relative
}

@media only screen and (max-width: 768px) {
    .ab_header .nav-list-right .notification-menu,.ab_header .nav-list-left .notification-menu {
        margin-right:0px;
        padding: .375rem !important
    }
}

@media only screen and (min-width: 769px) {
    .ab_header .nav-list-right .notification-menu,.ab_header .nav-list-left .notification-menu {
        min-width:1.125rem;
        min-height: 1.375rem
    }
}

.ab_header .nav-list-right .notification-menu .badge,.ab_header .nav-list-left .notification-menu .badge {
    left: 0.9rem;
    top: -0.4rem
}

@media only screen and (max-width: 768px) {
    .ab_header .nav-list-right .notification-menu .badge,.ab_header .nav-list-left .notification-menu .badge {
        background:#F42626;
        box-sizing: content-box;
        top: 0.25rem;
        left: 1.1rem;
        line-height: .5rem;
        font-size: .625rem;
        border-radius: .375rem;
        height: .875rem;
        font-weight: 600;
        max-height: .875rem;
        top: 0rem
    }
}

.ab_header .nav-list-right .list-item a,.ab_header .nav-list-left .list-item a {
    text-decoration: none;
    display: inline-flex;
    font-size: .875rem;
    font-weight: 500;
    line-height: normal;
    align-self: center;
    letter-spacing: normal;
    cursor: pointer;
    position: relative
}

@media only screen and (max-width: 768px) {
    .ab_header .nav-list-right .list-item a,.ab_header .nav-list-left .list-item a {
        color:#1E223C;
        padding: .6875rem
    }
}

.ab_header .nav-list-right .list-item a .mg-top-2,.ab_header .nav-list-left .list-item a .mg-top-2 {
    margin-top: 2px
}

.ab_header .nav-list-right .list-item a.purple-btn,.ab_header .nav-list-left .list-item a.purple-btn {
    font-size: .875rem;
    font-weight: 500;
    text-align: right;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgb(1, 80, 154);
    text-decoration: none
}

@media only screen and (max-width: 768px) {
    .ab_header .nav-list-right .list-item a.purple-btn,.ab_header .nav-list-left .list-item a.purple-btn {
        font-weight:600
    }
}

@media screen and (max-width: 890px) {
    .ab_header .nav-list-right .list-item a.purple-btn,.ab_header .nav-list-left .list-item a.purple-btn {
        text-align:left
    }
}

.ab_header .nav-list-right .list-item a.purple-btn:hover,.ab_header .nav-list-left .list-item a.purple-btn:hover {
    color: #4158d4
}

.ab_header .nav-list-right .red-dot,.ab_header .nav-list-left .red-dot {
    top: 0.6rem;
    right: 0.225rem
}

.ab_header .nav-list-right {
    width: 100%;
    justify-content: end !important
}

.ab_header .mg-top-1 {
    margin-top: 1px
}

.ab_header .ic {
    position: relative;
    width: 24px;
    height: 22px;
    display: inline-block
}

.ab_header .ic .line.one {
    top: 6px;
    transform-origin: right bottom
}

.ab_header .ic .line.two {
    top: 11px
}

.ab_header .ic .line.thr {
    transform-origin: right top;
    top: 16px
}

.ab_header .ic .line {
    position: absolute;
    left: 3px;
    right: 3px;
    height: 1.3px;
    background: #5E6B92;
    outline: 1px solid transparent
}

.ab_header .icon-search {
    font-size: 1.25rem;
    margin-top: .0625rem;
    padding: .625rem
}

@media only screen and (max-width: 768px) {
    .ab_header .icon-search {
        padding:0rem
    }
}

@media only screen and (max-width: 768px) {
    .ab_header .right-container {
        flex-direction:row-reverse
    }
}

.ab_header .right-container .nav-list-right .list-item {
    position: relative
}

@media only screen and (max-width: 575px) {
    .ab_header .right-container .nav-list-right .list-item .open-menu {
        padding:.5rem
    }
}

.ab_header .right-container .nav-list-right .list-item .login-btn {
    color: #4769f6
}

.ab_header .right-container .nav-list-right .list-item a {
    padding: .75rem
}

@media only screen and (max-width: 768px) {
    .ab_header .right-container .nav-list-right .list-item a {
        padding:.375rem
    }
}

.ab_header .right-container .nav-list-right .list-item .employer {
    padding-left: 1.5rem
}

.ab_header .right-container .nav-list-right .list-item.border-right::after {
    width: .0625rem;
    position: absolute;
    right: 0;
    content: '';
    top: calc(50% - 6px);
    background-color: #97A0BE;
    height: .75rem
}

@media only screen and (max-width: 768px) {
    .ab_header .right-container .nav-list-right .list-item.border-right::after {
        top:calc(50% - 15px);
        background-color: #e9e8f9;
        height: 1.875rem
    }
}

.ab_header .right-container .nav-list-right .list-item.border-right.white-br::after {
    background-color: #fff
}

.ab_header .right-container .nav-list-right .list-item .userinfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center
}

.ab_header .right-container .nav-list-right .list-item .userinfo .userimg {
    border-radius: 50%;
    width: 35px;
    margin-right: .625rem
}

.ab_header .right-container .nav-list-right .list-item:hover .dropdown {
    display: block
}

.ab_header .right-container .nav-list-right .list-item .dropdown {
    position: absolute;
    top: 100%;
    left: .5rem;
    display: none;
    z-index: 1000;
    box-shadow: 0 2px 6px 0 rgba(0,106,194,0.2);
    border: 1px solid #e9e8f9;
    background: #fff;
    padding: 14px 0;
    border-radius: 3px
}

.ab_header .right-container .nav-list-right .list-item .dropdown .a-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 24px;
    white-space: nowrap
}

.ab_header .right-container .nav-list-right .list-item .dropdown .a-row:hover {
    background-color: #f6f7ff
}

.ab_header .right-container .nav-list-right .list-item .dropdown .a-row .icon {
    font-size: 0.9rem
}

.ab_header .right-container .nav-list-right .list-item .dropdown::after {
    content: '';
    position: absolute;
    top: calc(-0.2rem - 1px);
    right: 1.5rem;
    transform: rotate(-45deg);
    width: 0.4rem;
    height: 0.4rem;
    background-color: white;
    border: solid 1px rgba(0,0,0,0.15);
    border-bottom: none;
    border-left: none
}

.ab_header .right-container .nav-list-right .awards_btn {
    border-radius: 1rem 0 0 1rem;
    border-top: 1px solid #c38e27;
    border-bottom: 1px solid #c38e27;
    border-left: 1px solid #c38e27;
    background: #151515;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 0 .625rem;
    margin-left: .5rem
}

.ab_header .right-container .nav-list-right .awards_btn span {
    background: linear-gradient(90deg, #c38e27 0%, #f7d590 107.17%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: .75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1rem;
    letter-spacing: 0.4px
}

.ab_header .right-container .nav-list-right .awards_btn .g-btn__img--sm {
    width: .875rem;
    height: .875rem
}

.ab_header .right-container .nav-list-right a.purple-btn {
    font-size: .875rem;
    font-weight: 500;
    text-align: right;
    line-height: 1.5;
    letter-spacing: normal;
    color: rgb(1, 80, 154);
    text-decoration: none
}

@media screen and (max-width: 890px) {
    .ab_header .right-container .nav-list-right a.purple-btn {
        text-align:left
    }
}

@media only screen and (max-width: 575px) {
    .ab_header .right-container .nav-list-right a.purple-btn {
        font-size:1rem
    }
}

.ab_header .right-container .nav-list-right a.purple-btn:hover {
    color: #4158d4
}

@media only screen and (max-width: 768px) {
    .ab_header .left-container {
        flex-direction:row-reverse
    }
}

.ab_header .left-container .nav-list-left .list-item a {
    color: #5E6B92
}

.ab_header .left-container .nav-list-left .list-item a.router-link-exact-active,.ab_header .left-container .nav-list-left .list-item a.nuxt-link-active,.ab_header .left-container .nav-list-left .list-item a:hover {
    color: #1E223C
}

.ab_header .user-link-btn {
    color: #1E223C
}

.ab_header .profileIcon {
    min-width: 4.25rem;
    min-height: 3.625rem
}

@media only screen and (min-width: 769px) {
    #contribute-button {
        text-decoration:none;
        display: inline-flex;
        font-size: .875rem;
        font-weight: 500;
        line-height: normal;
        align-self: center;
        letter-spacing: normal
    }
}

.nav-bar-wrapper {
    padding: 0px 1.875rem;
    background: #f6f7fe;
    display: flex;
    justify-content: space-between;
    border-bottom: 0.5px solid #e5e7f8;
    max-height: 2.5rem
}

.nav-bar-wrapper .employerNav {
    font-weight: 400;
    font-size: .875rem;
    line-height: .875rem;
    color: #333;
    display: flex;
    align-items: center
}

.nav-bar-wrapper .employerNav:hover {
    color: #4769f6
}

.nav-bar-wrapper .nav-list-wrapper {
    list-style-type: none
}

.nav-bar-wrapper .nav-list-wrapper .navItem {
    display: inline-flex;
    align-items: center;
    position: relative;
    font-weight: 600;
    font-size: .875rem;
    line-height: .875rem;
    color: #292929;
    align-items: center;
    cursor: pointer;
    height: 2.5rem;
    margin-left: 2.25rem
}

.nav-bar-wrapper .nav-list-wrapper .navItem--link:hover .navItemLink {
    color: #4769f6
}

.nav-bar-wrapper .nav-list-wrapper .navItem--hoverAwards:hover .navItemLink {
    color: #292929
}

.nav-bar-wrapper .nav-list-wrapper .navItem--hoverAwards .navItemLink:hover {
    color: #4769f6
}

.nav-bar-wrapper .nav-list-wrapper .navItem .navItemLink {
    color: #292929
}

.nav-bar-wrapper .nav-list-wrapper .navItem--badge {
    position: relative;
    top: -0.2rem;
    box-sizing: content-box;
    padding-left: .5rem
}

.nav-bar-wrapper .nav-list-wrapper .navItem--Infotag {
    object-fit: cover;
    margin-left: .25rem
}

.nav-bar-wrapper .nav-list-wrapper .navItem:hover .subNavItem {
    display: block
}

.nav-bar-wrapper .nav-list-wrapper .navItem:first-child {
    margin-left: 1.625rem
}

.nav-bar-wrapper .nav-list-wrapper .navItem .icon-chevron-right {
    transform: rotate(90deg);
    font-size: .875rem !important
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavItem {
    display: none;
    padding: 1.25rem;
    padding-top: .9375rem;
    position: absolute;
    top: 2.5rem;
    background: #fff;
    box-shadow: .25rem .5rem 1.125rem rgba(31,32,32,0.08);
    border-radius: .5rem;
    z-index: 2;
    width: 24.75rem;
    box-sizing: border-box
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavItem::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 10%;
    margin-left: -.3125rem;
    border-width: .3125rem;
    border-style: solid;
    border-color: transparent transparent #fff transparent
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavItem--awards {
    width: 27.25rem;
    padding: 1rem;
    padding-bottom: 1.25rem
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavItem .subNavWrapper {
    display: flex;
    align-items: center
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavItem .subNavWrapper:hover {
    background: #f5f6fc
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavItem .subNavWrapper .subNavImgBox {
    height: 3rem;
    width: 3rem;
    background: #F7F8FC;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .25rem
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavItem .subNavWrapper .nameDescWrapper {
    margin-left: 1.25rem
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavItem .subNavWrapper .nameDescWrapper .subNavDescription {
    margin-top: .25rem;
    font-weight: 500;
    font-size: .875rem;
    color: #656669
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavItem .subNavWrapper .subNavListItem {
    list-style: none;
    color: #292929
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavItem .subNavWrapper .subNavListItem--awards {
    display: flex;
    align-items: center;
    color: #000;
    font-size: .875rem;
    font-weight: 600;
    line-height: 1.25rem;
    letter-spacing: .00937rem
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavItem .subNavWrapper .subNavDescription--awards {
    color: #656669;
    font-size: .875rem !important;
    font-weight: 400 !important;
    line-height: 1.25rem !important;
    letter-spacing: .01562rem !important;
    margin-top: 0 !important
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavWrap {
    padding: .75rem;
    list-style-type: none;
    width: 22.25rem;
    box-sizing: border-box;
    border-radius: .25rem
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavWrap__animation_box {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid #ebf0f6;
    background: linear-gradient(282deg, #f6f7ff 0%, #fff 100%);
    z-index: -1
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavWrap__animation_box_line1 {
    margin-right: 4px;
    width: 6px;
    height: 100.457px;
    transform: translateX(-50px) rotate(30deg);
    flex-shrink: 0;
    background: rgba(233,233,255,0.5);
    z-index: -1;
    animation: slide1 4500ms ease-in-out infinite 0.5s
}

@keyframes slide1 {
    0% {
        transform: translateX(-50px) rotate(30deg)
    }

    50%,100% {
        transform: translate(426px) rotate(30deg)
    }
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavWrap__animation_box_line2 {
    width: 10.637px;
    height: 103.633px;
    transform: translateX(-50px) rotate(30deg);
    flex-shrink: 0;
    background: rgba(233,233,255,0.5);
    z-index: -1;
    animation: slide2 4500ms ease-in-out infinite 0.5s
}

@keyframes slide2 {
    0% {
        transform: translateX(-50px) rotate(30deg)
    }

    50%,100% {
        transform: translate(426px) rotate(30deg)
    }
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavWrap--awards {
    width: 100%;
    margin-bottom: .5rem;
    border-radius: .5rem
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavWrap--awards:hover {
    background: #f5f6fc
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavWrap:hover .subNavImgBox {
    background: #fff
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavWrap.shine {
    position: relative;
    outline: 1px solid #ebf0f6;
    background: linear-gradient(282deg, #f6f7ff 0%, #fff 100%) !important
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavWrap.shine:hover {
    outline: 1px solid #e4e6ee
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavWrap.shine:hover .subNavWrap__animation_box {
    border: 1px solid #e4e6ee
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavWrap.shine .subNavWrapper:hover {
    background: linear-gradient(282deg, #f6f7ff 0%, #fff 100%) !important
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavWrapAwards {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.75rem;
    position: relative
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavWrapAwards__text {
    color: #060606;
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: .01562rem;
    text-decoration: underline
}

.nav-bar-wrapper .nav-list-wrapper .navItem .subNavWrapAwards::before {
    content: '';
    position: absolute;
    top: -1.25rem;
    left: 0;
    width: 100%;
    background: #E4E6EE;
    height: 1px
}

.nav-bar-wrapper.invert {
    border: 0.5px solid rgba(229,231,248,0.25);
    border-width: 0.5px 0
}

.nav-bar-wrapper.invert .navItem,.nav-bar-wrapper.invert .icon-chevron-right,.nav-bar-wrapper.invert .icon-chevron-right:before,.nav-bar-wrapper.invert .navItemLink,.nav-bar-wrapper.invert .employerNav {
    color: #fff !important
}

.winner-chip {
    position: relative;
    cursor: pointer;
    margin-left: .25rem
}

.winner-chip__winnerBox {
    padding: .125rem .25rem;
    font-size: .625rem;
    position: relative;
    letter-spacing: .025rem;
    border-radius: .25rem;
    color: #ffff;
    overflow: hidden;
    line-height: 1.4;
    background: linear-gradient(91.32deg, #cb5bb2 2.15%, #f3747b 95.89%)
}

.winner-chip__img {
    position: absolute;
    top: -.5rem;
    right: -.6875rem
}

.winner-chip__animation_box_line1 {
    width: .75rem;
    height: 1.875rem;
    transform: translateX(0px) rotate(-15deg);
    flex-shrink: 0;
    background: linear-gradient(86.96deg, rgba(255,255,255,0) 3.28%, rgba(255,255,255,0.6) 48.34%, rgba(255,255,255,0) 93.41%);
    position: absolute;
    animation: slide1 2600ms ease-in-out infinite;
    top: -.3125rem
}

@keyframes slide1 {
    0% {
        transform: translateX(-10px) rotate(-15deg)
    }

    50%,100% {
        transform: translate(85px) rotate(-15deg);
        background-color: transparent
    }
}

@keyframes typewriter {
    90%,100% {
        width: 100%
    }
}

@keyframes multiLine {
    100% {
        transform: translateY(-300%)
    }
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.homepage-container .container,.homepage-container .container-left {
    max-width: 73.375rem;
    margin: 0 auto
}

@media (max-width: 1174px) {
    .homepage-container .container,.homepage-container .container-left {
        margin:0;
        padding: 0 1rem
    }
}

@media (max-width: 1174px) {
    .homepage-container .container-left {
        padding:0
    }
}

.homepage-container.pd-top {
    padding-top: 3.375rem;
    padding-bottom: 3rem
}

@media only screen and (min-width: 769px) {
    .homepage-container.pd-top {
        padding-top:6.1875rem
    }
}

.homepage-container .home-hover-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    min-width: 20%;
    padding: 0.5rem 1rem;
    gap: 1rem;
    background: #fff;
    border: 1px solid rgb(1, 80, 154);
    box-shadow: 0.25rem 0.25rem 0px rgb(1, 80, 154);
    border-radius: 0.75rem;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 150%;
    color: #1E223C
}

@media only screen and (min-width: 769px) {
    .homepage-container .home-hover-button {
        font-size:1.25rem
    }
}

.homepage-container .home-hover-button:hover {
    background: rgb(1, 80, 154);
    box-shadow: 0.25rem 0.25rem 0px #1E223C;
    color: #fff
}

.homepage-container .cta-underline {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 150%;
    color: rgb(1, 80, 154);
    width: fit-content;
    cursor: pointer
}

@media only screen and (min-width: 769px) {
    .homepage-container .cta-underline {
        font-size:1.25rem
    }
}

.homepage-container .cta-underline__container {
    display: flex;
    align-items: center;
    gap: 0.5rem
}

.homepage-container .cta-underline__text {
    color: rgb(1, 80, 154)
}

.homepage-container .cta-underline__icon {
    font-size: 1rem;
    color: rgb(1, 80, 154)
}

@media only screen and (min-width: 769px) {
    .homepage-container .cta-underline__icon {
        font-size:1.125rem
    }
}

.homepage-container .cta-underline:after {
    display: block;
    content: '';
    border-bottom: 2px solid rgb(1, 80, 154);
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%
}

.homepage-container .cta-underline:hover {
    color: rgb(1, 80, 154)
}

.homepage-container .cta-underline:hover * {
    color: rgb(1, 80, 154);
}

.homepage-container .cta-underline:hover:after {
    transform: scaleX(1)
}

.homepage-container .typewriter-text {
    display: inline-block;
    vertical-align: bottom
}

.homepage-container .typewriter-text>span {
    display: grid;
    overflow: hidden;
    height: 1.2em
}

.homepage-container .typewriter-text span span {
    width: 0%;
    max-width: max-content;
    overflow: hidden;
    height: inherit;
    word-break: break-all;
    animation: typewriter 2s linear infinite alternate,multiLine 12s steps(3) infinite;
    background: transparent;
    background-image: linear-gradient(90.04deg, #2151cc -62.27%, rgb(1, 80, 154) 47.82%, #7eaff6 149.1%);
    background-clip: text;
    -webkit-background-clip: text;
    text-fill-color: transparent;
    -webkit-text-fill-color: transparent
}

.homepage-container .typewriter-text span span:before {
    content: ' ';
    display: inline-block
}

.homepage-container .viewAllCompanies {
    font-size: 1.125rem
}

@media only screen and (min-width: 769px) {
    .homepage-container .viewAllCompanies {
        font-size:1.25rem
    }
}

.homepage-container .slider_btns {
    height: 3rem;
    width: 3rem;
    box-shadow: 0px 3px 12px rgba(0,0,0,0.15);
    border-radius: 1.5rem;
    opacity: 1
}

.homepage-container .slider_btns .icon-chevron-right:before,.homepage-container .slider_btns .icon-chevron-left:before {
    color: #1E223C
}

.homepage-container .slider_btns:hover {
    background: #1E223C;
    box-shadow: none;
    border: none
}

.homepage-container .slider_btns:hover .icon-chevron-right:before,.homepage-container .slider_btns:hover .icon-chevron-left:before {
    color: #fff
}

.homepage-container .contentSlider__container>div {
    margin-right: 0.75rem
}

.homepage-container .contentSlider__container>div:first-child {
    margin-left: 1rem
}

@media only screen and (min-width: 769px) {
    .homepage-container .contentSlider__container>div:first-child {
        margin-left:0
    }
}

.homepage-container .contentSlider__container>div:last-child {
    margin-right: 1rem
}

@media only screen and (min-width: 769px) {
    .homepage-container .contentSlider__container>div:last-child {
        margin-right:0
    }
}

.homepage-container .slider_next {
    right: -1.5rem
}

@media (max-width: 1232px) {
    .homepage-container .slider_next {
        right:0
    }
}

.homepage-container .slider_prev {
    left: -1.5rem
}

@media (max-width: 1232px) {
    .homepage-container .slider_prev {
        left:0
    }
}

.homepage-container .homeTypeahead .ab-typeahead__menu {
    left: -40px !important;
    width: calc(100% + 40px) !important
}

.homepage-container .hero-section {
    position: relative;
    display: flex;
    width: 100%;
    background: #fafbff;
    height: auto
}

.homepage-container .hero-section .fadeIn {
    animation-name: fadeIn;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    transition-timing-function: ease-out
}

@media only screen and (min-width: 769px) {
    .homepage-container .hero-section {
        min-height:25rem
    }
}

.homepage-container .hero-section__blurimageLine {
    position: absolute;
    top: 15%;
    left: -2.25rem;
    height: 12.875rem;
    width: 12.875rem;
    border-radius: 50%;
    background: linear-gradient(214.97deg, #c8edf9 -22.7%, #b8c3ff 63.93%, #fff 100.2%);
    filter: blur(500px)
}

@-moz-document url-prefix() {
    .homepage-container .hero-section__blurimageLine {
        filter: opacity(0.3) blur(500px)
    }
}

@media only screen and (min-width: 769px) {
    .homepage-container .hero-section__blurimageLine {
        left:2rem;
        top: 30%
    }
}

.homepage-container .hero-section__illustration {
    display: none
}

@media only screen and (min-width: 769px) {
    .homepage-container .hero-section__illustration {
        display:block;
        position: absolute;
        right: 0;
        left: unset;
        max-width: 48%;
        object-fit: contain
    }
}

@media only screen and (min-width: 1385px) {
    .homepage-container .hero-section__illustration {
        display:block;
        position: absolute;
        bottom: 0;
        left: 58%;
        object-fit: contain
    }
}

@media (max-width: 900px) {
    .homepage-container .hero-section__illustration {
        display:none
    }
}

.homepage-container .hero-section__textSection {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2.5rem 0 2.0625rem
}

@media only screen and (min-width: 769px) {
    .homepage-container .hero-section__textSection {
        padding:4.25rem 0 2.75rem
    }
}

.homepage-container .hero-section__title {
    font-weight: 700;
    line-height: 120%;
    color: #1E223C;
    margin-bottom: 0;
    width: 100%;
    font-size: 1.75rem
}

@media only screen and (min-width: 769px) {
    .homepage-container .hero-section__title {
        margin-bottom:.5rem;
        font-size: 3rem
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .homepage-container .hero-section__title {
        font-size:2.5rem
    }
}

.homepage-container .hero-section__personalizedTitle {
    font-weight: 700;
    line-height: 120%;
    color: #1E223C;
    margin-bottom: 0
}

@media only screen and (min-width: 769px) {
    .homepage-container .hero-section__personalizedTitle {
        margin-bottom:.5rem;
        font-size: 3rem;
        width: 36.25rem;
        word-break: break-word
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .homepage-container .hero-section__personalizedTitle {
        font-size:2.5rem
    }
}

@media only screen and (max-width: 575px) {
    .homepage-container .hero-section__personalizedTitle {
        width:100%;
        word-break: break-word;
        font-size: 5.5vw
    }
}

.homepage-container .hero-section__personalizedTitle:first-letter {
    text-transform: uppercase
}

.homepage-container .hero-section__searchbox {
    width: 100%;
    /* z-index: 5 */
}

@media only screen and (min-width: 769px) {
    .homepage-container .hero-section__searchbox {
        width:50%
    }
}

.homepage-container .hero-section__search-input {
    display: flex;
    align-items: center;
    padding: .5rem 1.25rem;
    gap: .625rem;
    background: #fff;
    border: 1px solid #e9e9e9;
    box-shadow: 0 0 1.25rem rgba(0,0,0,0.05);
    border-radius: 3.5rem
}

@media (max-width: 1024px) and (min-width: 768px) {
    .homepage-container .hero-section__search-input {
        padding:.875rem 1.5rem
    }
}

.homepage-container .hero-section__search-text {
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.75rem;
    color: #5E6B92
}

.homepage-container .hero-section__recently-viewed {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    height: 4.5rem
}

@media only screen and (min-width: 769px) {
    .homepage-container .hero-section__recently-viewed {
        height:auto
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .homepage-container .hero-section__recently-viewed {
        height:auto
    }
}

.homepage-container .hero-section__recent-heading {
    display: flex;
    align-items: center;
    gap: .25rem
}

.homepage-container .hero-section__popular-heading {
    display: flex;
    align-items: center;
    gap: .4375rem
}

.homepage-container .hero-section__recent-title {
    margin-top: 1px;
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.6875rem;
    color: #5e6b92
}

.homepage-container .hero-section__recent-chips {
    display: flex;
    gap: 0.75rem;
    align-items: center;
    flex-wrap: wrap;
    max-height: 5.125rem;
    overflow: hidden
}

@media only screen and (min-width: 769px) {
    .homepage-container .hero-section__recent-chips {
        max-width:50%
    }
}

.homepage-container .hero-section__recent-chip {
    background: #fff;
    border: 1px solid #e9e9e9;
    border-radius: .25rem;
    cursor: pointer;
    margin: 0;
    padding: 0;
    max-width: 100%;
    overflow: hidden
}

.homepage-container .hero-section__recent-chip:hover {
    background: #fafbff;
    border: 1px solid rgb(1, 80, 154)
}

.homepage-container .hero-section__recent-chip:hover .hero-section__chip-text {
    color: #495ac8
}

.homepage-container .hero-section__chip-text {
    margin: .4375rem .5rem;
    font-weight: 500;
    font-size: .75rem;
    line-height: 130%;
    color: #1E223C;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

@media only screen and (min-width: 769px) {
    .homepage-container .hero-section__chip-text {
        font-size:.875rem
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .homepage-container .hero-section__chip-text {
        font-size:.875rem
    }
}

.homepage-container .companies-section {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background: linear-gradient(180deg, rgba(242,242,242,0) 0%, #f2f2f2 100%);
    padding: 1.25rem 0 2.25rem;
    overflow: hidden
}

@media only screen and (min-width: 769px) {
    .homepage-container .companies-section {
        padding:2rem 0
    }
}

.homepage-container .companies-section__feed-widget {
    padding: 1.25rem 1rem;
    border-radius: 0.75rem;
    background: linear-gradient(89deg, #093885 4.78%, #2360ac 173.63%);
    box-shadow: 0px 0px 60px 2px rgba(0,0,0,0.15);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    transition: 0.15s linear;
    cursor: pointer
}

@media only screen and (min-width: 769px) {
    .homepage-container .companies-section__feed-widget {
        margin:0 auto;
        border-radius: 1rem;
        padding: 2.5rem
    }

    .homepage-container .companies-section__feed-widget:hover {
        transform: scale(1.05);
        transition: 0.15s linear
    }
}

.homepage-container .companies-section__feed-widget-content {
    position: relative;
    z-index: 2;
    width: calc(100% - 127px)
}

.homepage-container .companies-section__feed-widget-content .feed-conversation-btn {
    font-size: 0.75rem;
    line-height: 1rem;
    display: inline-flex;
    height: 2rem
}

@media only screen and (min-width: 769px) {
    .homepage-container .companies-section__feed-widget-content .feed-conversation-btn {
        font-size:1rem;
        line-height: 1.5rem;
        height: 2.5rem
    }
}

.homepage-container .companies-section__feed-widget-content .feed-conversation-btn:hover {
    background: #fff;
    box-shadow: 0.25rem 0.25rem 0px rgb(1, 80, 154);
    color: #1E223C
}

.homepage-container .companies-section__feed-widget-image {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 116px;
    object-fit: cover;
    border-radius: 0 0.75rem 0.75rem 0
}

@media only screen and (min-width: 769px) {
    .homepage-container .companies-section__feed-widget-image {
        width:477px
    }
}

.homepage-container .companies-section__feed-widget-overlay {
    position: absolute;
    z-index: 1;
    width: 127px;
    height: 100%;
    right: 13px;
    background: linear-gradient(90deg, #124692 18.56%, rgba(17,68,145,0) 96.45%)
}

@media only screen and (min-width: 769px) {
    .homepage-container .companies-section__feed-widget-overlay {
        right:192px;
        width: 358px;
        background: linear-gradient(90deg, #114491 22.67%, rgba(17,68,145,0) 96.45%)
    }
}

.homepage-container .companies-section__feed-widget-title {
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    letter-spacing: 0.15px;
    color: white;
    margin-bottom: 1rem
}

@media only screen and (min-width: 769px) {
    .homepage-container .companies-section__feed-widget-title {
        font-size:28px;
        line-height: 2.25rem;
        margin-bottom: 2rem
    }
}

.homepage-container .companies-section__insight-section {
    display: flex;
    margin-top: 1.25rem
}

@media only screen and (max-width: 575px) {
    .homepage-container .companies-section__insight-section {
        flex-direction:column
    }
}

@media only screen and (min-width: 769px) {
    .homepage-container .companies-section__insight-section {
        gap:0;
        justify-content: space-between;
        margin-top: 2rem
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .homepage-container .companies-section__insight-section {
        gap:0rem;
        justify-content: space-between
    }
}

.homepage-container .companies-section__insights {
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center
}

@media only screen and (min-width: 769px) {
    .homepage-container .companies-section__insights {
        flex-direction:row;
        gap: 0;
        align-items: center;
        width: 50%;
        justify-content: space-around
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .homepage-container .companies-section__insights {
        display:flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-between;
        width: auto
    }
}

.homepage-container .companies-section__insight-item {
    display: flex;
    flex-direction: column;
    padding: 0.75rem;
    gap: 0.875rem;
    border-radius: 0.5rem
}

@media only screen and (min-width: 769px) {
    .homepage-container .companies-section__insight-item {
        flex-direction:row;
        padding: 1.5rem;
        gap: 1rem
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .homepage-container .companies-section__insight-item {
        flex-direction:column;
        padding: 0.75rem;
        gap: 0.875rem
    }
}

.homepage-container .companies-section__insight-image {
    object-fit: contain
}

.homepage-container .companies-section__insight-data {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 8.25rem
}

@media only screen and (min-width: 769px) {
    .homepage-container .companies-section__insight-data {
        width:10.75rem
    }
}

@media (min-width: 1024px) and (max-width: 1120px) {
    .homepage-container .companies-section__insight-data {
        width:auto
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .homepage-container .companies-section__insight-data {
        width:9.5rem
    }
}

.homepage-container .companies-section__insight-title {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 120%;
    letter-spacing: 0.01em;
    color: #1E223C
}

@media only screen and (min-width: 769px) {
    .homepage-container .companies-section__insight-title {
        font-size:1.5rem
    }
}

.homepage-container .companies-section__insight-subtitle {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 140%;
    color: #394468
}

@media only screen and (min-width: 769px) {
    .homepage-container .companies-section__insight-subtitle {
        font-size:1rem
    }
}

.homepage-container .companies-section__dt {
    display: none
}

@media only screen and (min-width: 769px) {
    .homepage-container .companies-section__dt {
        display:flex
    }
}

.homepage-container .companies-section__pwa {
    display: flex
}

@media only screen and (min-width: 769px) {
    .homepage-container .companies-section__pwa {
        display:none
    }
}

.homepage-container .companies-section__company-slider {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0;
    margin-top: 4rem
}

@media only screen and (min-width: 769px) {
    .homepage-container .companies-section__company-slider {
        gap:2rem
    }
}

.homepage-container .companies-section__company-title {
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 150%;
    color: #1E223C;
    text-align: center;
    padding: 0 1rem
}

@media only screen and (min-width: 769px) {
    .homepage-container .companies-section__company-title {
        font-size:1.5rem
    }
}

.homepage-container .companies-section__company-cards {
    margin-bottom: 0.5rem
}

@media only screen and (min-width: 769px) {
    .homepage-container .companies-section__company-cards {
        margin:0
    }
}

.homepage-container .explore-section {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 4rem;
    overflow: hidden
}

@media only screen and (min-width: 769px) {
    .homepage-container .explore-section {
        padding-top:4rem;
        padding-bottom: 6rem
    }
}

.homepage-container .explore-section__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem
}

.homepage-container .explore-section__heading {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem
}

.homepage-container .explore-section__title {
    font-weight: 800;
    font-size: 1.25rem;
    line-height: 150%;
    color: #1E223C
}

@media only screen and (min-width: 769px) {
    .homepage-container .explore-section__title {
        font-size:1.5rem
    }
}

.homepage-container .explore-section__subtitle {
    font-weight: 500;
    font-size: 1rem;
    line-height: 150%;
    color: #394468;
    text-align: center
}

.homepage-container .explore-section__explore-cards {
    display: flex;
    flex-direction: column;
    gap: 4rem
}

@media only screen and (min-width: 769px) {
    .homepage-container .explore-section__explore-cards {
        gap:6rem
    }
}

.homepage-container .mg-btm-sep {
    margin-bottom: 4rem
}

@media only screen and (min-width: 769px) {
    .homepage-container .mg-btm-sep {
        margin-bottom:6rem
    }
}

.homepage-container .media-slider {
    height: 5.875rem
}

@media only screen and (min-width: 769px) {
    .homepage-container .media-slider {
        height:4.875rem
    }
}

@media (max-width: 1174px) {
    .homepage-container .media-slider {
        margin-left:-1.5rem;
        margin-right: -1.5rem;
        width: 100vw
    }
}

.homepage-container .media-slider .slider-content {
    width: 146.75rem;
    animation-duration: 22s
}

.homepage-container .media-slider .logo {
    width: auto;
    height: 1.875rem;
    object-fit: contain;
    margin-right: 5rem
}

.homepage-container .media-slider .slider-item:first-child {
    margin-right: 8.125rem
}

@media only screen and (min-width: 769px) {
    .homepage-container .media-slider .slider-item:first-child {
        margin-right:12.5rem
    }
}

.homepage-container .media-slider .adjust-size {
    height: 1.875rem
}

.ab-typeahead {
    width: 100%;
    padding: .5rem 0.5rem .5rem 0;
    display: inline;
    position: relative
}

.ab-typeahead+div {
    background-color: #fff !important;
    line-height: normal
}

.ab-typeahead__menu {
    position: absolute;
    z-index: 100;
    background-color: #fff;
    box-shadow: 0 .5625rem .875rem 0 rgba(88,95,135,0.1);
    border: 0;
    left: 0;
    top: calc(100% + 1.2rem);
    border-radius: .75rem;
    width: 100%;
    max-height: 20.625rem;
    overflow-y: auto;
    width: 100%
}

.ab-typeahead__menu::-webkit-scrollbar-track {
    background: transparent
}

.ab-typeahead__menu::-webkit-scrollbar-thumb {
    border-radius: .3125rem;
    background-color: #c4c4c4
}

.ab-typeahead__dataset {
    list-style-type: none !important;
    box-shadow: none;
    border: 0;
    background-color: #fff;
    padding: 1.25rem 0
}

.ab-typeahead__dataset .section-header {
    display: flex;
    padding: 0 1.25rem .25rem;
    justify-content: space-between;
    align-items: center
}

@media only screen and (max-width: 768px) {
    .ab-typeahead__dataset .section-header {
        padding:0 1rem .625rem
    }
}

.ab-typeahead__dataset .section-header p {
    color: #5E6B92;
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.43
}

.ab-typeahead__dataset .section-header p:nth-child(2) {
    cursor: pointer;
    display: flex
}

.ab-typeahead__dataset .section-header p i {
    padding: 0;
    margin-left: .3125rem;
    font-size: 1.0625rem
}

.ab-typeahead__dataset.company-search {
    margin-bottom: -1.5rem
}

.ab-typeahead__dataset.company-search:empty {
    padding-top: .75rem
}

@media only screen and (max-width: 768px) {
    .ab-typeahead__dataset.company-search:empty {
        padding-top:0
    }
}

@media only screen and (max-width: 768px) {
    .ab-typeahead__dataset.company-search {
        margin-bottom:-1rem
    }
}

.ab-typeahead__dataset.desig-search {
    padding-top: 1.25rem;
    padding-bottom: 0.625rem
}

.ab-typeahead__dataset.desig-search:empty {
    padding-bottom: .375rem
}

@media only screen and (max-width: 768px) {
    .ab-typeahead__dataset.desig-search {
        padding:1.25rem 0
    }
}

.ab-typeahead__suggestion {
    display: flex;
    cursor: pointer;
    overflow: hidden;
    background-color: #fff;
    padding: .5rem 1.25rem
}

@media only screen and (max-width: 768px) {
    .ab-typeahead__suggestion {
        padding:.5rem 1rem
    }
}

@media only screen and (min-width: 1280px) {
    .ab-typeahead__suggestion:hover,.ab-typeahead__suggestion.tt-cursor {
        background-color:#f5f7fd
    }
}

.ab-typeahead__suggestion .logo_wrap {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: .25rem;
    margin-right: .5rem
}

.ab-typeahead__suggestion .logo_wrap img {
    background: #fff;
    object-fit: contain;
    border-radius: .125rem;
    border: solid 1px #f0f0f0;
    border: 1px solid #E4E6EE;
    height: inherit;
    width: inherit
}

.ab-typeahead__suggestion .top-row {
    line-height: 1.25rem
}

.ab-typeahead__suggestion .top-row .info_text {
    font-size: .875rem;
    font-weight: 400;
    letter-spacing: normal;
    color: #1E223C
}

.ab-typeahead__suggestion .top-row .info_text--700 {
    font-weight: 700
}

.ab-typeahead__suggestion .top-row .rating-wrapper {
    align-items: center;
    line-height: 1.33;
    display: inline-flex
}

.ab-typeahead__suggestion .top-row .rating-wrapper .rating {
    color: #fff;
    display: inline-flex;
    padding: .125rem;
    border-radius: .125rem;
    width: 2.25rem;
    height: 1rem;
    font-size: .6875rem;
    line-height: 1.33;
    align-items: center;
    margin-right: 0.25rem
}

@media only screen and (max-width: 768px) {
    .ab-typeahead__suggestion .top-row .rating-wrapper .rating {
        font-size:.625rem
    }
}

.ab-typeahead__suggestion .top-row .rating-wrapper .rating i {
    width: .6875rem;
    font-size: .6875rem;
    color: #fff;
    padding: 0 .125rem .0625rem .0625rem;
    margin-right: .25rem
}

.ab-typeahead__suggestion .top-row .rating-wrapper .rating span {
    color: #fff;
    display: inline-block;
    line-height: 1.2;
    font-size: .625rem
}

.ab-typeahead__suggestion .top-row .rating-wrapper .review-count {
    color: #97A0BE;
    font-size: .6875rem;
    line-height: 1rem;
    letter-spacing: .025rem
}

.ab-typeahead__suggestion .info_text {
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: .01562rem;
    margin-right: 12px;
    color: #1e223c
}

@media only screen and (max-width: 768px) {
    .ab-typeahead__suggestion .info_text {
        letter-spacing:.00625rem
    }
}

.ab-typeahead__suggestion .info_text p strong {
    font-weight: 600
}

.ab-typeahead__suggestion.desig_wrap {
    align-items: center
}

.ab-typeahead__suggestion.desig_wrap .logo_wrap {
    height: 1rem;
    margin-top: -.375rem
}

@media only screen and (max-width: 768px) {
    .ab-typeahead__suggestion.desig_wrap .logo_wrap {
        height:1.25rem;
        margin-top: 0
    }
}

.ab-typeahead__suggestion.desig_wrap .info_text {
    font-weight: 400;
    font-size: .875rem
}

@media only screen and (max-width: 768px) {
    .ab-typeahead__suggestion.desig_wrap .info_text {
        font-size:.875rem
    }
}

.ab-typeahead__noresults {
    display: block;
    padding: 1rem 1.5rem
}

@media only screen and (max-width: 768px) {
    .ab-typeahead__noresults {
        padding:1.25rem 1rem
    }

    .ab-typeahead__noresults p {
        font-size: 0.8125rem;
        line-height: 1.23
    }

    .ab-typeahead__noresults .suggest-results {
        color: #5E6B92
    }
}

.ab_typeahead4 {
    background-repeat: no-repeat
}

.ab_typeahead4 .subtitle {
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.3;
    color: #fff
}

.ab_typeahead4 .typeahead-title {
    padding-top: 3rem;
    line-height: normal;
    margin: 0;
    padding-bottom: 0
}

.wdth-466 {
    width: 466px
}

.typeahead-wrapper3-padding {
    padding: 1.5rem 0 3rem 0 !important
}

.typeahead-wrapper3 .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1.5rem;
    background-color: #fff;
    padding: 0.5rem;
    max-width: 46.5625rem;
    width: 100%;
    height: 3.25rem;
    border: solid 1px #E4E6EE;
    box-shadow: 0 2px 6px -2px rgba(0,106,194,0.2)
}

.typeahead-wrapper3 .wrapper-wthout-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 1.5rem;
    background-color: #fff;
    padding: 0.5rem;
    max-width: 46.5625rem;
    width: 100%;
    height: 2.25rem;
    border: solid 1px #D6D6D6
}

.typeahead-wrapper3 .home-wrapper {
    background: #fff;
    border: 1px solid #e9e9e9;
    box-shadow: 0px 0px 20px rgba(0,0,0,0.05);
    border-radius: 3.5rem;
    padding: .6875rem .75rem;
    height: auto
}

.typeahead-wrapper3 .home-wrapper:hover {
    border: 1px solid rgb(1, 80, 154);
    box-shadow: 0px 0px 0px 4px #eaecfa
}

.typeahead-wrapper3 .home-wrapper:hover .home-dropdown p {
    font-weight: 700
}

.typeahead-wrapper3 .home-wrapper:focus-within {
    border: 1px solid rgb(1, 80, 154);
    box-shadow: 0px 0px 0px 4px #eaecfa
}

.typeahead-wrapper3 .home-wrapper:focus-within .home-dropdown p {
    font-weight: 700
}

.typeahead-wrapper3 .search-company {
    width: calc(100% - 20rem);
    width: 348px;
    height: 2.1875rem;
    flex: 1;
    border-radius: 1.5rem 0 0 1.5rem;
    background-color: #fff;
    outline: none;
    display: flex;
    align-items: center;
    position: relative
}

.typeahead-wrapper3 .search-company input {
    width: 100%;
    height: 100%;
    border: 0;
    outline: none;
    direction: ltr;
    font-size: .875rem;
    padding-left: 1.5rem;
    font-weight: 500;
    color: #5E6B92
}

.typeahead-wrapper3 .search-company input::placeholder {
    color: #97A0BE
}

.typeahead-wrapper3 .search-company input:focus {
    color: #1E223C
}

.typeahead-wrapper3 .search-company-wth-btn {
    width: 348px;
    height: 2rem
}

.typeahead-wrapper3 .search-company-wth-btn .icon-search {
    margin-top: 0px;
    font-size: 1.125rem;
    padding: .5rem .375rem .3125rem .375rem
}

.typeahead-wrapper3 .search-company-wth-btn .icon-search::before {
    color: #97A0BE
}

.typeahead-wrapper3 .search-company-wth-btn .search-icon {
    margin: .375rem .375rem .3125rem .375rem
}

.typeahead-wrapper3 .search-company-wth-btn input {
    padding-left: .375rem
}

.typeahead-wrapper3 .home-search-bar {
    width: 65%
}

.typeahead-wrapper3 .dropdown {
    width: 12.1875rem;
    position: relative
}

.typeahead-wrapper3 .dropdown::before {
    width: .0625rem;
    content: '';
    background: #e4e6ee;
    z-index: 1;
    height: 50%;
    position: absolute;
    left: 0;
    top: 25%
}

.typeahead-wrapper3 .dropdown .autocomplete-list {
    top: calc(100% + 1.05rem);
    border-radius: .75rem;
    max-height: unset;
    padding: .625rem 0
}

.typeahead-wrapper3 .dropdown .autocomplete-list li {
    border-bottom: none !important;
    padding: .5625rem 1.5rem
}

.typeahead-wrapper3 .dropdown .autocomplete-list li.active {
    color: rgb(1, 80, 154);
    font-weight: 500
}

.typeahead-wrapper3 .dropdown .wrapper-dropdown {
    border: 0 !important;
    height: 2.5rem;
    border-radius: 0
}

.typeahead-wrapper3 .dropdown .wrapper-dropdown .input-box {
    padding: 0 1.5rem;
    justify-content: flex-start
}

.typeahead-wrapper3 .dropdown .wrapper-dropdown .input-box p {
    color: #5E6B92
}

.typeahead-wrapper3 .dropdown .wrapper-dropdown .input-box i {
    font-size: 1rem;
    margin-left: 0.125rem
}

.typeahead-wrapper3 .dropdown .wrapper-dropdown .list-open {
    border: 0 !important;
    max-height: unset !important
}

.typeahead-wrapper3 .dropdown-without-btn {
    width: 11.125rem;
    position: relative
}

@media screen and (max-width: 1065px) {
    .typeahead-wrapper3 .dropdown-without-btn {
        width:7.5rem
    }
}

.typeahead-wrapper3 .dropdown-without-btn::before {
    width: .0625rem;
    content: '';
    background: #e4e6ee;
    z-index: 1;
    height: 50%;
    position: absolute;
    left: 0;
    top: 25%
}

.typeahead-wrapper3 .dropdown-without-btn .autocomplete-list {
    top: calc(100% + 1.05rem);
    border-radius: .75rem;
    max-height: unset;
    padding: .625rem 0
}

.typeahead-wrapper3 .dropdown-without-btn .autocomplete-list li {
    border-bottom: none !important;
    padding: .5625rem 1.5rem
}

.typeahead-wrapper3 .dropdown-without-btn .autocomplete-list li.active {
    color: rgb(1, 80, 154);
    font-weight: 500
}

.typeahead-wrapper3 .dropdown-without-btn .wrapper-dropdown {
    border: 0 !important;
    height: 1.9375rem;
    border-radius: 5px
}

.typeahead-wrapper3 .dropdown-without-btn .wrapper-dropdown .input-box {
    padding: 0 1rem;
    justify-content: space-between;
    height: 2rem !important
}

.typeahead-wrapper3 .dropdown-without-btn .wrapper-dropdown .input-box p {
    color: #606D94
}

.typeahead-wrapper3 .dropdown-without-btn .wrapper-dropdown .input-box i {
    font-size: 1rem;
    margin-left: 0.125rem
}

.typeahead-wrapper3 .dropdown-without-btn .wrapper-dropdown .list-open {
    border: 0 !important;
    max-height: unset !important;
    z-index: 3
}

.typeahead-wrapper3 .home-dropdown {
    width: 35%
}

.typeahead-wrapper3 .home-dropdown p {
    color: #2a2f4f !important
}

.typeahead-wrapper3 .close {
    margin-right: .5rem
}

.typeahead-wrapper3 .search-btn {
    min-width: 6.75rem;
    box-shadow: none
}

.typeahead-wrapper3.float-search {
    padding: 0 0 1.25rem 0 !important;
    margin-top: -.25rem
}

.typeahead-wrapper3.float-search .wrapper {
    margin: auto;
    height: 3rem;
    padding: .375rem .5rem
}

.dsply-inline {
    display: inline !important
}

.border-suggestion-box[data-v-6a0700ed] {
    border: solid 2px #a0a8c4 !important;
    border-bottom: 0px !important
}

.border-input-box[data-v-6a0700ed] {
    overflow: unset !important;
    border-radius: 4px 4px 0px 0px !important
}

.wrapper-dropdown[data-v-6a0700ed] {
    position: relative;
    width: 100%;
    border-radius: 4px;
    border: solid 2px #E4E6EE;
    background-color: #fff;
    overflow: hidden
}

.wrapper-dropdown .input-box[data-v-6a0700ed] {
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 24px
}

@media only screen and (max-width: 768px) {
    .wrapper-dropdown .input-box[data-v-6a0700ed] {
        padding:12px 16px
    }
}

.wrapper-dropdown .input-box p[data-v-6a0700ed] {
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.43;
    letter-spacing: normal;
    color: #1E223C
}

.wrapper-dropdown .input-box i[data-v-6a0700ed] {
    transform: rotate(90deg)
}

.wrapper-dropdown .autocomplete-list[data-v-6a0700ed] {
    position: absolute;
    left: -2px;
    right: -2px;
    box-shadow: 0 9px 14px 0 rgba(88,95,135,0.1);
    background-color: #fff;
    list-style: none;
    overflow-y: hidden;
    max-height: 0px;
    border: solid 2px #a0a8c4;
    border-top: 0px;
    border-bottom: solid 2px #E4E6EE;
    z-index: 2;
    transition: max-height 250ms ease-in;
    -webkit-transition: max-height 250ms ease-in;
    will-change: max-height
}

.wrapper-dropdown .autocomplete-list.list-open[data-v-6a0700ed] {
    max-height: 200px;
    height: auto;
    border: solid 2px #a0a8c4;
    border-top: 0px;
    overflow-y: auto
}

.wrapper-dropdown .autocomplete-list li[data-v-6a0700ed] {
    font-size: 0.9rem;
    margin: 0;
    padding: 12px 24px;
    border-bottom: solid 1px #ebf0f6;
    background-color: #fff;
    cursor: pointer;
    user-select: none;
    font-size: .875rem;
    font-weight: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #1E223C
}

.wrapper-dropdown .autocomplete-list li[data-v-6a0700ed]:hover {
    background-color: #f5f7fd
}

@media only screen and (max-width: 768px) {
    .wrapper-dropdown .autocomplete-list li[data-v-6a0700ed] {
        padding:12px 16px
    }
}

@media only screen and (max-width: 768px) {
    .wrapper-dropdown .autocomplete-list li.padding-fix[data-v-6a0700ed] {
        padding:12px
    }
}

@media only screen and (max-width: 768px) {
    .component-wrapper-dropdown[data-v-6a0700ed] {
        margin-bottom:20px;
        border-radius: 2px;
        box-shadow: 0 2px 6px -2px rgba(0,106,194,0.2);
        border: solid 1px #ebf0f6
    }
}

@media only screen and (max-width: 768px) {
    .component-wrapper-dropdown .component-input-box[data-v-6a0700ed] {
        padding:10px 10px 10px 12px
    }
}

.contentSlider {
    width: 100%;
    position: relative
}

.contentSlider__wrpr {
    height: 100%;
    width: 100%;
    overflow: hidden
}

.contentSlider__leftMover {
    position: absolute;
    top: 45%;
    left: -1.5625rem;
    z-index: 1000;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
    transform: rotate(-180deg);
    cursor: pointer
}

.contentSlider__leftMover:hover {
    background: #000
}

.contentSlider__leftMover:hover .icon-chevron-right:before {
    color: #fff
}

.contentSlider__rightMover {
    position: absolute;
    top: 45%;
    right: -.875rem;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
    cursor: pointer
}

.contentSlider__rightMover:hover {
    background: #000
}

.contentSlider__rightMover:hover .icon-chevron-right:before {
    color: #fff
}

.contentSlider__paginationWpr {
    width: 100%;
    height: 1.875rem;
    margin-top: .625rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute
}

.contentSlider__paginationWpr__page {
    width: .9375rem;
    height: .9375rem;
    background: #fff;
    border-radius: 50%;
    margin-right: .625rem;
    cursor: pointer
}

.contentSlider__paginationWpr__currentPage {
    background: #000
}

.contentSlider .icon-chevron-right {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.5rem
}

.contentSlider .icon-chevron-right:before {
    color: #000
}

.contentSlider__slide {
    box-sizing: border-box;
    width: 100%
}

.contentSlider__container {
    max-width: 100%;
    height: 10%
}

@media only screen and (max-width: 768px) {
    .contentSlider__container {
        -ms-overflow-style:none;
        scrollbar-width: none
    }

    .contentSlider__container::-webkit-scrollbar {
        display: none
    }
}

.popular-company-card {
    display: flex;
    flex-direction: column;
    margin-right: .9375rem;
    cursor: pointer;
    border-radius: .75rem .75rem 0 0
}

@media only screen and (min-width: 769px) {
    .popular-company-card {
        min-width:19.375rem;
        max-width: 23.75rem
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .popular-company-card {
        min-width:17.1875rem;
        max-width: 21.875rem
    }
}

.popular-company-card__image-container {
    overflow: hidden;
    position: relative;
    border-radius: .75rem .75rem 0 0;
    height: 12.5rem
}

.popular-company-card__image {
    width: 100%;
    transform: scale(1);
    transition: all 0.3s;
    object-fit: cover
}

.popular-company-card__image:hover {
    transform: scale(1.1);
    vertical-align: middle
}

.popular-company-card__details {
    background-color: #fff;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: #e9e9e9;
    border-radius: 0 0 .75rem .75rem;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    padding: 1rem;
    min-height: calc(100% - 12.5rem)
}

.popular-company-card__logo {
    object-fit: contain;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 0.5rem
}

.popular-company-card__subdetails {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
    max-width: 15rem
}

@media only screen and (min-width: 769px) {
    .popular-company-card__subdetails {
        max-width:16.5rem
    }
}

.popular-company-card__head {
    display: flex;
    align-items: flex-start;
    justify-content: space-between
}

.popular-company-card__name {
    font-weight: 700;
    font-size: 1rem;
    line-height: 150%;
    color: #2A2F4F;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 75%
}

.popular-company-card__industry {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 125%;
    color: #5E6B92;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    max-width: 80%
}

@media only screen and (min-width: 769px) {
    .popular-company-card__industry {
        font-size:0.875rem
    }
}

.popular-company-card .rating {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: .25rem;
    width: 2.625rem;
    height: 1.3125rem;
    justify-content: space-between;
    border-radius: .25rem
}

@media only screen and (min-width: 769px) {
    .popular-company-card .rating {
        min-width:3rem;
        max-height: 1.5rem
    }
}

.popular-company-card .rating span {
    color: #fff
}

@media only screen and (max-width: 768px) {
    .popular-company-card .rating span {
        font-size:.75rem;
        line-height: 1
    }
}

.popular-company-card .rating .icon {
    font-size: 1rem;
    color: #fff;
    display: inline-block;
    margin-top: -.125rem;
    vertical-align: middle
}

@media only screen and (max-width: 768px) {
    .popular-company-card .rating .icon {
        font-size:.875rem
    }
}

.explore-card {
    display: flex;
    flex-direction: column;
    gap: 1.5rem
}

.explore-card:first-child {
    padding-top: 2.5rem
}

@media only screen and (min-width: 769px) {
    .explore-card {
        flex-direction:row;
        align-items: center;
        justify-content: space-between
    }
}

.explore-card__img {
    min-width: 100%;
    border-radius: 1.25rem;
    object-fit: cover
}

@media only screen and (min-width: 410px) {
    .explore-card__img {
        min-width:unset;
        margin: 0 auto;
        object-fit: unset
    }
}

@media only screen and (min-width: 769px) {
    .explore-card__img {
        min-width:unset;
        max-width: 55%;
        margin: 0
    }
}

@media (max-width: 1024px) and (min-width: 768px) {
    .explore-card__img {
        max-width:55%;
        margin: 0;
        object-fit: cover
    }
}

.explore-card__details {
    display: flex;
    flex-direction: column;
    gap: 1.25rem
}

@media only screen and (min-width: 769px) {
    .explore-card__details {
        justify-content:center;
        gap: 1.5rem;
        max-width: 42%
    }
}

.explore-card__title {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 130%;
    color: #1E223C
}

@media only screen and (min-width: 769px) {
    .explore-card__title {
        font-size:2rem;
        line-height: 150%
    }
}

.explore-card__links {
    display: flex;
    flex-direction: column;
    gap: 1.125rem
}

.explore-card .home-hover-button {
    align-self: flex-start !important;
    margin-top: 0.75rem
}

@media only screen and (min-width: 769px) {
    .explore-card .home-hover-button {
        margin-top:1rem
    }
}

.contriPitch {
    border-radius: 1.25rem;
    padding: 2.5rem 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    text-align: center;
    background: linear-gradient(88.03deg, #2948ff -45.97%, #54df8b 135.51%)
}

@media only screen and (min-width: 769px) {
    .contriPitch {
        text-align:left
    }
}

@media only screen and (min-width: 769px) {
    .contriPitch::after {
        content:'';
        position: absolute;
        left: 119px;
        bottom: 0;
        height: 15.3125rem;
        width: 24.5625rem;
        background-repeat: no-repeat;
        z-index: 0;
        background-size: 24.5625rem 15.3125rem
    }
}

@media only screen and (min-width: 769px) {
    .contriPitch__info {
        padding-right:3rem
    }
}

@media only screen and (min-width: 769px) {
    .contriPitch {
        flex-direction:row;
        padding: 3rem 6.5rem 3rem 3rem;
        border-radius: 2.5rem
    }
}

.contriPitch__rewardsInfo {
    z-index: 1;
    width: 100%;
    max-width: 23.125rem
}

@media only screen and (max-width: 768px) {
    .contriPitch__rewardsInfo {
        max-width:unset;
        margin-top: 2rem
    }
}

.contriPitch__rewardCard.globalCard {
    width: 100%;
    margin-bottom: 1.5rem;
    padding: .75rem 1rem;
    text-align: left
}

@media only screen and (min-width: 769px) {
    .contriPitch__rewardCard.globalCard {
        padding:1.5rem
    }
}

.contriPitch__rewardCard.globalCard .ab-user-image {
    width: 3rem;
    height: 3rem
}

.contriPitch__rewardCard.globalCard .text-cont {
    margin-left: 1.25rem
}

.contriPitch__rewardCard.globalCard .info .count {
    font-size: 1rem;
    font-weight: 600;
    color: #2A2F4F;
    margin-bottom: .125rem
}

@media only screen and (min-width: 769px) {
    .contriPitch__rewardCard.globalCard .info .count {
        font-size:1.25rem
    }
}

.contriPitch__rewardCard.globalCard .info .desc-text {
    font-weight: 500;
    color: #4E597B
}

@media only screen and (max-width: 768px) {
    .contriPitch__rewardCard.globalCard .info .desc-text {
        font-size:.75rem
    }
}

.contriPitch__msg {
    font-weight: 500;
    color: #fff;
    font-size: 1rem;
    line-height: 1.5;
    margin-top: 1.5rem;
    display: flex;
    justify-content: center
}

.contriPitch__title {
    font-weight: 700;
    color: #fff;
    font-size: 1.5rem;
    line-height: 1.3
}

@media only screen and (min-width: 769px) {
    .contriPitch__title {
        font-size:2rem;
        line-height: 1.5
    }
}

.contriPitch__subtitle {
    font-weight: 500;
    color: #fff;
    font-size: 1rem;
    line-height: 1.5;
    margin-top: 0.5rem
}

@media only screen and (min-width: 769px) {
    .contriPitch__subtitle {
        font-size:1.25rem
    }
}

.contriPitch img {
    display: inline;
    vertical-align: middle
}

.ab-rewards-contri-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 20.125rem;
    cursor: pointer;
    transition: 0.15s linear
}

.ab-rewards-contri-card .negativeMargin {
    margin-top: -15px
}

.ab-rewards-contri-card:hover,.ab-rewards-contri-card:active {
    transform: scale(1.05);
    transition: 0.15s linear
}

@media only screen and (max-width: 575px) {
    .ab-rewards-contri-card {
        width:100%;
        margin-top: 1.25rem
    }
}

.ab-rewards-contri-card.ab_card {
    padding: 1.25rem;
    box-shadow: none
}

.ab-rewards-contri-card .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%
}

.ab-rewards-contri-card .info .ab-user-image {
    width: 3.75rem;
    height: 3.75rem
}

@media only screen and (max-width: 575px) {
    .ab-rewards-contri-card .info .ab-user-image {
        width:2.5rem;
        height: 2.5rem
    }
}

.ab-rewards-contri-card .info .text-cont {
    margin-left: 1.25rem
}

@media only screen and (max-width: 575px) {
    .ab-rewards-contri-card .info .text-cont {
        margin-left:.75rem
    }
}

.ab-rewards-contri-card .info .count {
    margin-top: 0;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    color: #292929;
    margin-bottom: .25rem
}

.ab-rewards-contri-card .info .desc-text {
    max-width: 100%;
    font-size: .875rem;
    color: #6E6D79;
    line-height: 1.25rem
}

.ab-rewards-contri-card .icon-chevron-right:before {
    color: #979FB8
}

.ab-rewards-contri-card.flex-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 2px
}

.ab-rewards-contri-card.flex-col .contribute_btn {
    margin-left: 4.75rem
}

.ab-rewards-contri-card.flex-col .info {
    margin-bottom: .5rem
}

.ab-rewards-contri-card.flex-col .info .ab-user-image {
    width: 3.75rem;
    height: 3.75rem
}

.card .ab-rewards-contri-card.flex-col .contribute_btn {
    margin-left: 4rem
}

.card .ab-rewards-contri-card.flex-col .info {
    margin-bottom: .5rem
}

.card .ab-rewards-contri-card.flex-col .info .ab-user-image {
    width: 3rem;
    height: 3rem
}

.rejected_icon {
    position: absolute;
    bottom: 40px;
    left: 54px
}

.arrow-right-icon {
    width: 10.67px !important;
    height: 9.34px !important
}

.globalCard {
    background-color: #fff;
    border: 1px solid #ebf0f6;
    border-radius: .375rem;
    padding: 1.25rem;
    box-sizing: border-box;
    position: relative
}

.globalCard__wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
}

.globalCard--elevated {
    box-shadow: 0px 2px 6px -2px rgba(0,106,194,0.2)
}

.globalCard--filled {
    background: linear-gradient(281.95deg, #f6f7ff 0%, #fff 100%)
}

.tooltip {
    position: relative;
    display: inline-block
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: auto;
    width: 284px;
    background-color: #5E6B92;
    font-size: .6875rem;
    font-weight: 500;
    line-height: 1.64;
    text-align: center;
    color: #ebf0f6;
    border-radius: 6px;
    padding: 11px 12px;
    position: absolute;
    z-index: 10000000;
    bottom: 160%;
    left: 50%;
    white-space: normal;
    margin-left: -142px
}

@media only screen and (max-width: 575px) {
    .tooltip .tooltiptext {
        left:155%;
        width: 16.125rem;
        padding: 11px 10px
    }
}

.tooltip .tooltiptext::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #5E6B92 transparent transparent transparent
}

.tooltip:hover .tooltiptext,.tooltiptext {
    display: block !important;
    visibility: visible
}

.tooltip.right {
    display: inline-block;
    vertical-align: middle
}

.tooltip.right .tooltiptext {
    width: 16.125rem;
    z-index: 50;
    left: -74.66667px;
    bottom: -720%
}

@media only screen and (min-width: 769px) {
    .tooltip.right .tooltiptext {
        bottom:-225%;
        text-align: left;
        left: 162px
    }
}

.tooltip.right .tooltiptext::after {
    margin-top: -.625rem;
    transform: rotate(180deg);
    top: 0%;
    left: 86%
}

@media only screen and (min-width: 769px) {
    .tooltip.right .tooltiptext::after {
        transform:rotate(90deg);
        top: 50%;
        left: 0%;
        margin-top: 0;
        margin-left: -.625rem
    }
}

.tooltip.tipBottom {
    display: inline-block;
    vertical-align: middle
}

.tooltip.tipBottom .tooltiptext {
    width: 16.5625rem;
    z-index: 50;
    left: -74.66667px;
    top: 180%;
    height: max-content;
    font-size: .875rem;
    line-height: 1.3125rem;
    text-align: left;
    right: 162px
}

@media only screen and (max-width: 768px) {
    .tooltip.tipBottom .tooltiptext {
        left:0
    }
}

.tooltip.tipBottom .tooltiptext::after {
    transform: rotate(-180deg);
    top: -13%;
    left: 84%;
    width: .3125rem;
    position: absolute;
    margin-left: -.3125rem;
    border-width: .375rem;
    border-style: solid;
    border-color: #5E6B92 transparent transparent transparent
}

@media only screen and (max-width: 768px) {
    .tooltip.tipBottom .tooltiptext::after {
        left:55%;
        top: -18%
    }
}

.ab_img_text_cont {
    display: flex;
    align-items: flex-end
}

@media only screen and (max-width: 575px) {
    .ab_img_text_cont {
        flex-direction:column;
        align-items: flex-start
    }
}

.ab_img_text_cont .text-cont {
    margin-left: 1.5rem
}

@media only screen and (max-width: 575px) {
    .ab_img_text_cont .text-cont {
        margin-left:0
    }
}

.ab_img_text_cont .text-wrap {
    display: flex;
    align-items: center
}

.ab_img_text_cont .count {
    margin-bottom: .375rem
}

@media only screen and (max-width: 575px) {
    .ab_img_text_cont .count {
        margin-top:1.375rem;
        margin-bottom: .5rem
    }
}

@media only screen and (max-width: 575px) {
    .ab_img_text_cont .desc-text {
        max-width:4.375rem
    }
}

.ab_img_text_cont .status-message {
    background-color: #fff5e4;
    font-size: 0.8rem;
    font-weight: 600;
    text-align: center;
    color: #d18d02;
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 0.125rem 0.4375rem;
    line-height: normal;
    border-radius: 0px 4px 0px 4px
}

.ab_img_text_cont .status-message img {
    margin-right: .1875rem;
    height: 0.65rem;
    vertical-align: middle
}

.ab_img_text_cont .status-message span {
    vertical-align: middle
}

.ab_img_text_cont .info_badge {
    margin-left: 6px;
    margin-bottom: 22px;
    cursor: pointer
}

.ab_img_text_cont .tooltip .tooltiptext {
    transform: translate(-30%);
    bottom: 135%;
    left: 0
}

@media only screen and (max-width: 575px) {
    .ab_img_text_cont .tooltip .tooltiptext {
        transform:translate(-30%)
    }
}

.ab_img_text_cont .tooltip .tooltiptext::after {
    left: 85%
}

@media only screen and (max-width: 575px) {
    .ab_img_text_cont .tooltip .tooltiptext::after {
        left:90%
    }
}

.ab_img_text_cont .new-badge-wrap {
    display: flex;
    align-items: center;
    margin-left: 10px;
    border: solid #FFFFFF;
    border-radius: 4px;
    border-width: 1px;
    padding: 2px 4px;
    border-radius: 4px;
    background: linear-gradient(91deg, #CB5BB2 2.15%, #F3747B 95.89%)
}

.ab_img_text_cont .new-badge-wrap .new-badge {
    font-size: 10px;
    line-height: 10px;
    font-weight: 400;
    color: #FFFFFF;
    height: 10px;
    width: 23px
}

.ab-user-image {
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem
}

.ab-user-image.not-rounded {
    border-radius: 0
}

.slider {
    position: relative;
    width: 100%;
    height: 7.875rem;
    overflow: hidden
}

.slider .slider-content {
    position: absolute;
    top: 50%;
    width: 274%;
    left: 0;
    transform: translate(0, -50%);
    display: flex;
    animation: scroll 12s linear infinite
}

.slider .slider-content:hover {
    animation-play-state: paused
}

.slider .slider-content .slider-item {
    width: 50%;
    display: flex;
    align-items: center
}

@keyframes scroll {
    0% {
        left: 0
    }

    100% {
        left: -137%
    }
}

.slider .shadow-left,.slider .shadow-right {
    box-shadow: .3125rem 0 1.25rem 1.5625rem #1a1a1a;
    height: 4rem;
    top: 3.4375rem;
    position: absolute;
    z-index: 99
}

.slider .shadow-right {
    right: 0
}

.slide {
    max-width: 100%;
    margin: auto;
    display: grid
}

.slide-items {
    position: relative;
    grid-area: 1/1;
    overflow: hidden
}

.slide-nav {
    width: 100%;
    height: 100%;
    grid-area: 1/1;
    z-index: 1;
    display: grid;
    position: absolute;
    top: 0;
    left: 0;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr
}

.slide-nav button {
    pointer-events: all;
    min-height: 22.375rem;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    opacity: 0
}

.slide-items>.slide-item {
    position: absolute;
    top: 0px;
    opacity: 0;
    pointer-events: none
}

.slide-items>.active {
    position: relative;
    opacity: 1;
    pointer-events: initial
}

.slide-thumb {
    display: flex;
    grid-column: 1 / 3;
    justify-content: center
}

.slide-thumb>span {
    flex: 1;
    cursor: pointer;
    display: block;
    margin: 0 .25rem;
    overflow: hidden;
    padding: 1rem 0;
    position: relative;
    background-color: transparent
}

.slide-thumb>span::before {
    content: '';
    z-index: 1;
    height: .25rem;
    width: 100%;
    display: block;
    background: #484848;
    border-radius: .25rem
}

.slide-thumb>span.active::after {
    content: '';
    z-index: 1;
    display: block;
    height: .25rem;
    position: absolute;
    top: calc(50% - 2px);
    overflow: hidden;
    width: 100%;
    background: #1E223C;
    transform: translateX(-100%);
    border-radius: .25rem;
    animation: thumb 5s forwards linear
}

@keyframes thumb {
    to {
        transform: initial
    }
}

.testCont {
    position: relative
}

.testCont__title {
    line-height: 1.5;
    font-weight: 800;
    text-align: center
}

@media only screen and (min-width: 769px) {
    .testCont__title {
        font-size:1.5rem
    }
}

.testCont__subtitle {
    color: #394468;
    text-align: center;
    font-weight: 500;
    margin-top: .75rem;
    margin-bottom: 2rem
}

@media only screen and (min-width: 769px) {
    .testCont__subtitle {
        margin-bottom:2.5rem
    }
}

.testCont__subtitle img {
    display: inline;
    vertical-align: middle
}

.testCont__slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.testCont__testmon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 22.375rem;
    background-color: #f2f2f2;
    border-radius: 1.25rem;
    padding: 2rem;
    z-index: 1
}

@media only screen and (min-width: 769px) {
    .testCont__testmon {
        border-radius:2.5rem;
        padding: 2.5rem
    }
}

.testCont__text {
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.3;
    color: #2A2F4F
}

@media only screen and (min-width: 769px) {
    .testCont__text {
        line-height:2.8125rem;
        font-size: 1.75rem
    }
}

.testCont__userInfo {
    margin-top: 2rem;
    justify-self: flex-end
}

.testCont__name {
    font-weight: 700;
    line-height: 1.5;
    font-size: 1.25rem;
    color: #394468
}

.testCont__bgimg {
    position: absolute;
    z-index: 0
}

@media only screen and (max-width: 768px) {
    .testCont__bgimg {
        display:none
    }
}

.testCont__img1 {
    left: 1.25rem;
    bottom: 6.3125rem
}

.testCont__img2 {
    left: 2.875rem;
    bottom: 21.5rem
}

.testCont__img3 {
    right: 2.0625rem;
    bottom: 22.25rem
}

.testCont__img4 {
    right: .625rem;
    bottom: 4.3125rem
}

.testCont .slide {
    max-width: 40rem;
    min-height: 22.5rem
}

.testCont .slide-thumb {
    margin-top: 1rem;
    width: 100%;
    margin-bottom: -1rem
}

@media only screen and (min-width: 769px) {
    .testCont .slide-thumb {
        margin-top:1.5rem
    }
}

.testCont .slide-thumb span {
    max-width: 3.5625rem
}

.apw-2022 {
    align-items: center;
    background: linear-gradient(75.7deg, #0b0b0b -15.07%, #121212 81.9%, #212121 105.16%);
    cursor: pointer;
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 2.75rem 1rem 2.5rem;
    border-radius: 1.25rem
}

.apw-2022__spotlight {
    position: absolute;
    left: .625rem;
    top: -1.75rem
}

@media only screen and (max-width: 575px) {
    .apw-2022__spotlight {
        left:2.8125rem
    }
}

.apw-2022__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    bottom: 0
}

@media only screen and (min-width: 769px) {
    .apw-2022 {
        padding:3.75rem 4rem 3.75rem;
        padding-left: 4.875rem;
        flex-direction: row;
        border-radius: 2.5rem;
        max-height: 22.125rem
    }
}

.apw-2022__badge {
    object-fit: cover;
    z-index: 2
}

@media only screen and (min-width: 769px) {
    .apw-2022__badge {
        margin-bottom:0;
        margin-right: 5.625rem
    }
}

.apw-2022__info {
    text-align: center;
    z-index: 2
}

@media only screen and (min-width: 769px) {
    .apw-2022__info {
        text-align:left
    }
}

.apw-2022__logo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg, #c38e27 0%, #f7d590 107.17%);
    height: .125rem;
    width: 9.5rem
}

@media only screen and (min-width: 769px) {
    .apw-2022__logo {
        justify-content:flex-start
    }
}

.apw-2022__logo .text {
    color: #fff;
    letter-spacing: 0.02187rem;
    font-weight: 700;
    margin-left: .5625rem;
    font-size: 1.125rem
}

.apw-2022__title {
    font-size: 2rem;
    line-height: 1.3;
    letter-spacing: 0.600588px;
    font-weight: 700;
    background: linear-gradient(90deg, #d3d3d3 6.85%, #fff 48.27%, #9a9a9c 95.21%);
    margin: 1rem 0 0.5rem;
    margin-top: 2.75rem
}

@media only screen and (min-width: 769px) {
    .apw-2022__title {
        font-size:3rem;
        line-height: 1.2;
        margin-top: 1.5rem
    }
}

.apw-2022__subtitle {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    color: #FDFDFD;
    margin-bottom: 1.5rem
}

@media only screen and (min-width: 769px) {
    .apw-2022__subtitle {
        font-size:1.25rem
    }
}

.apw-2022 .grd-txt {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text
}

.apw-2022__viewall {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-weight: 600;
    background: linear-gradient(90deg, #c38e27 0%, #f7d590 107.17%);
    justify-content: center;
    font-size: 1.125rem
}

@media only screen and (min-width: 769px) {
    .apw-2022__viewall {
        justify-content:flex-start;
        font-size: 1.25rem
    }
}

.apw-2022__viewall .icon {
    font-size: 1rem;
    line-height: 1.5;
    margin-left: .5625rem
}

.apw-2022__viewall-cont {
    display: inline-block
}

.apw-2022__viewall-cont:after {
    display: block;
    content: '';
    border-bottom: 2px solid #e5b976;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%
}

.apw-2022:hover .apw-2022__viewall-cont:after {
    transform: scaleX(1)
}

.empPitch {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    border: 1px solid #E9E9E9;
    border-radius: 1.25rem;
    padding: 2.5rem 1rem;
    cursor: pointer;
    margin-bottom: 1rem
}

@media only screen and (min-width: 769px) {
    .empPitch {
        flex-direction:row;
        justify-content: center;
        padding: 3rem;
        border-radius: 2.5rem;
        margin-bottom: 3rem
    }
}

@media only screen and (min-width: 769px) {
    .empPitch__info {
        padding-right:3.5rem
    }
}

.empPitch__companies {
    display: flex;
    flex-direction: row;
    min-height: 2.5rem;
    margin: 1.25rem 0 1.5625rem
}

.empPitch__companies img {
    margin-right: .75rem;
    object-fit: contain;
    border-radius: .5rem;
    border: 1px solid #ebf0f6
}

.empPitch__link {
    display: flex;
    align-content: center
}

.empPitch__link .icon {
    font-size: 1rem;
    margin-left: 0.5rem;
    line-height: 1.5
}

.empPitch__title {
    line-height: 1.3
}

@media only screen and (min-width: 769px) {
    .empPitch__title {
        font-size:2rem;
        line-height: 1.5
    }
}

.empPitch__badge {
    line-height: 1.5;
    font-weight: 600;
    text-align: center;
    padding: .25rem .5rem;
    background: #f2f2f2;
    border-radius: .25rem;
    margin-bottom: .5rem;
    display: inline-block
}

@media only screen and (min-width: 769px) {
    .empPitch__badge {
        font-size:.875rem
    }
}

.empPitch__img {
    width: 100%;
    max-width: 19.375rem;
    object-fit: contain
}

@media only screen and (max-width: 768px) {
    .empPitch__img {
        margin-top:3.125rem;
        max-width: 18.5rem
    }
}

.empPitch .cta-underline {
    display: inline-block
}

.empPitch:hover .cta-underline {
    color: #495ac8
}

.empPitch:hover .cta-underline * {
    color: #495ac8
}

.empPitch:hover .cta-underline:after {
    transform: scaleX(1)
}

.empPitch .tray-enter-active {
    transition-duration: 1.5s;
    transition-timing-function: ease-in-out
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-duration: 2.5s;
    animation-name: fadeIn
}

#ab-footer {
    background-color: #f6f7ff
}

#ab-footer.hide {
    display: none
}

#ab-footer .icon-chevron-right {
    transform: rotate(90deg);
    vertical-align: middle;
    display: inline-block;
    margin: 0 -.125rem
}

#ab-footer .icon-chevron-right::before {
    color: #1E223C
}

#ab-footer .tooltip-wrapper {
    padding-bottom: 8px
}

#ab-footer .tooltip-wrapper:hover .tooltip {
    display: flex;
    flex-direction: column
}

#ab-footer .tooltip-wrapper .hover {
    display: inline-block;
    cursor: pointer
}

#ab-footer .tooltip-wrapper .tooltip {
    display: none;
    padding: 1rem;
    margin-top: .5rem;
    position: absolute;
    background: #fff;
    box-shadow: .25rem .5rem 1.125rem rgba(31,32,32,0.08);
    border-radius: .25rem;
    z-index: 2;
    width: 15rem;
    height: 6.5rem;
    box-sizing: border-box
}

#ab-footer .tooltip-wrapper .tooltip li {
    cursor: pointer;
    padding: 8px;
    margin: 0px
}

#ab-footer .tooltip-wrapper .tooltip li:hover {
    color: rgb(1, 80, 154)
}

#ab-footer .tooltip-wrapper .tooltip::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 10%;
    margin-left: -.3125rem;
    border-width: .3125rem;
    border-style: solid;
    border-color: transparent transparent #fff transparent
}

#ab-footer .download_link {
    cursor: pointer;
    line-height: 1.7
}

#ab-footer .download_link:hover {
    color: rgb(1, 80, 154)
}

#ab-footer.app {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 3.75rem
}

#ab-footer .col {
    padding-left: 0
}

#ab-footer .col li {
    margin-bottom: .5rem;
    line-height: 1
}

@media only screen and (max-width: 768px) {
    #ab-footer .col li {
        margin-bottom:.4375rem
    }
}

#ab-footer ul {
    list-style-type: none
}

#ab-footer .container {
    max-width: 67rem
}

#ab-footer .container .info .logo-wrap {
    display: flex;
    align-items: center
}

#ab-footer .container .info .logo-wrap .logo-img {
    width: 1.7rem;
    height: 1.9375rem;
    vertical-align: middle
}

#ab-footer .container .info .logo-wrap .logo-text {
    font-size: 1.325rem;
    font-weight: 700;
    letter-spacing: 0.36px;
    color: #040a1e;
    margin-left: 9px;
    vertical-align: middle
}

#ab-footer .container .info .title {
    margin: .9375rem auto .625rem;
    font-size: 1rem;
    font-weight: 600 !important;
    line-height: 1.5
}

@media only screen and (max-width: 575px) {
    #ab-footer .container .info .title {
        font-size:.8125rem;
        font-weight: normal;
        margin: .375rem auto 1rem
    }
}

#ab-footer .container .info .col {
    display: flex;
    flex-wrap: wrap;
    width: 75%
}

@media only screen and (max-width: 768px) {
    #ab-footer .container .info .col {
        padding:0;
        width: 100%
    }
}

#ab-footer .container .info .col .item {
    min-width: 25%
}

#ab-footer .container .info .col .item span {
    font-size: 1.125rem;
    line-height: 0.89
}

#ab-footer .container .info .col .item p {
    font-size: .875rem;
    line-height: 1.07
}

@media only screen and (max-width: 768px) {
    #ab-footer .container .info .col .item p {
        font-size:.75rem;
        font-weight: normal
    }
}

#ab-footer .btn {
    margin-bottom: .5rem
}

#ab-footer .btn.invert {
    background-color: transparent
}

@media only screen and (max-width: 768px) {
    #ab-footer .btn {
        margin-right:.5rem
    }
}

#ab-footer .btn span {
    font-size: .875rem;
    font-weight: 600
}

@media only screen and (max-width: 768px) {
    #ab-footer .btn span {
        font-size:.8125rem
    }
}

#ab-footer .ab_typeahead4 {
    padding: 0
}

#ab-footer .ab_typeahead4 .typeahead-wrapper3 {
    padding: 0
}

#ab-footer .ab_typeahead4 .typeahead-wrapper3 .wrapper {
    margin: 0
}

#ab-footer .ab_typeahead2 {
    width: 100%;
    padding: 0
}

#ab-footer .ab_typeahead2 #typeahead-wrapper2 {
    padding: 1rem 0 2.25rem
}

#ab-footer .footer-nav-section {
    padding: 2.5rem 0 2rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start
}

@media only screen and (max-width: 768px) {
    #ab-footer .footer-nav-section {
        align-items:center;
        flex-direction: column;
        margin-top: 1.5rem;
        padding: .875rem 0 0;
        border-top: solid 1px #e9e8f9
    }
}

#ab-footer .footer-nav-section .left-col {
    display: flex;
    min-width: 80%;
    flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap
}

@media only screen and (max-width: 768px) {
    #ab-footer .footer-nav-section .left-col {
        min-width:100%
    }
}

#ab-footer .footer-nav-section .left-col .col {
    min-width: auto;
    padding-right: 1.25rem;
    display: flex;
    flex-direction: column
}

@media only screen and (max-width: 768px) {
    #ab-footer .footer-nav-section .left-col .col {
        min-width:unset;
        width: 50%;
        margin-bottom: 1.5rem;
        padding-right: 0rem
    }
}

#ab-footer .footer-nav-section .left-col .col .awards {
    margin-top: -.1875rem
}

#ab-footer .footer-nav-section .left-col a,#ab-footer .footer-nav-section .left-col .footer-link {
    cursor: pointer;
    text-decoration: none;
    color: #1E223C;
    line-height: 1.71;
    margin: 0 0rem .5rem 0
}

#ab-footer .footer-nav-section .left-col a:hover,#ab-footer .footer-nav-section .left-col .footer-link:hover {
    color: rgb(1, 80, 154)
}

@media only screen and (max-width: 768px) {
    #ab-footer .footer-nav-section .left-col a,#ab-footer .footer-nav-section .left-col .footer-link {
        padding:0;
        font-size: .75rem !important;
        margin: 0 0rem .4375rem 0
    }
}

#ab-footer .footer-nav-section .left-col a.awards {
    display: flex;
    align-items: center
}

#ab-footer .footer-nav-section .left-col a.awards img {
    margin-left: 4px
}

@media only screen and (max-width: 575px) {
    #ab-footer .footer-nav-section .left-col a.awards img {
        margin-left:2px
    }
}

#ab-footer .footer-nav-section .bold-title {
    font-size: 1.125rem;
    margin-bottom: .375rem;
    display: inline-block;
    line-height: 1.56
}

@media only screen and (max-width: 768px) {
    #ab-footer .footer-nav-section .bold-title {
        font-size:1rem;
        line-height: 1.75
    }
}

#ab-footer .footer-nav-section .right-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

#ab-footer .footer-nav-section .right-col .item {
    display: block;
    padding: .25rem 0
}

#ab-footer .social {
    display: flex;
    margin-left: 14px
}

#ab-footer .social li {
    display: flex;
    align-items: center
}

@media only screen and (max-width: 768px) {
    #ab-footer .social {
        margin-bottom:0
    }
}

#ab-footer .social a {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 .75rem 0 0
}

#ab-footer .social #Youtube {
    border-radius: 5px
}

#ab-footer .contribute-mobile {
    font-weight: 600;
    padding: .9375rem 1.0625rem;
    background-color: #e9e8f9;
    margin: 0 -1rem;
    margin-bottom: 24px;
    text-align: left
}

#ab-footer .contribute-mobile p {
    font-weight: 600 !important;
    margin-bottom: .5rem;
    text-align: left
}

#ab-footer a {
    cursor: pointer
}

@media only screen and (max-width: 768px) {
    #ab-footer a {
        font-size:.875rem
    }
}

#ab-footer .support-mobile {
    margin: 0px 1rem;
    padding: 1.8125rem 0;
    border-top: 1px solid #e6e5f7;
    text-align: center;
    word-break: break-word
}

#ab-footer .support-mobile li {
    border-right: 1px solid #E4E6EE;
    padding-right: .625rem;
    margin-right: .625rem;
    margin-bottom: .625rem;
    font-weight: 600;
    color: #1E223C;
    white-space: nowrap;
    display: inline-block
}

#ab-footer .support-mobile li:last-child {
    border-right: none
}

#ab-footer .support-mobile a {
    font-weight: 600;
    color: #1E223C;
    white-space: nowrap
}

#ab-footer .copyright-container {
    padding: 1.5625rem 0;
    position: relative;
    border-top: solid 1px #e9e8f9;
    display: flex;
    justify-content: space-between;
    align-items: center
}

@media only screen and (max-width: 575px) {
    #ab-footer .copyright-container {
        border-top:none;
        padding: 1.25rem 0 .4375rem 0;
        flex-direction: column-reverse
    }
}

#ab-footer .copyright-container p {
    text-align: left
}

@media only screen and (max-width: 768px) {
    #ab-footer .copyright-container p {
        text-align:center;
        line-height: 1.33
    }
}

#ab-footer .app-prom-footer {
    margin-top: 10px;
    padding: 3px 0px
}

@media only screen and (max-width: 768px) {
    #ab-footer .app-prom-footer {
        margin:0 1rem;
        padding: 1.25rem 0;
        align-items: center;
        border-top: 1px solid #e6e5f7;
        border-bottom: 1px solid #e6e5f7
    }
}

#ab-footer .app-prom-footer__title {
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.15px;
    color: #1E223C
}

#ab-footer .app-prom-footer__title--white {
    color: #fff
}

#ab-footer .app-prom-footer__link {
    width: fit-content;
    margin: 12px 0 0 0 !important
}

#ab-footer.black-theme {
    background-color: #090800
}

#ab-footer.black-theme .logo-img {
    filter: brightness(0) invert(1)
}

#ab-footer.black-theme a,#ab-footer.black-theme .footer-link {
    color: rgba(255,255,255,0.7) !important
}

#ab-footer.black-theme .info p {
    color: rgba(255,255,255,0.75)
}

@media only screen and (max-width: 768px) {
    #ab-footer.black-theme .info p {
        color:rgba(255,255,255,0.8);
        font-weight: 500
    }
}

#ab-footer.black-theme .footer-nav-section {
    border-top-color: rgba(233,232,249,0.2)
}

@media only screen and (max-width: 768px) {
    #ab-footer.black-theme span,#ab-footer.black-theme .title,#ab-footer.black-theme a:hover {
        opacity:0.96
    }
}

#ab-footer.black-theme .title {
    font-weight: 400
}

@media only screen and (max-width: 768px) {
    #ab-footer.black-theme .title {
        font-weight:500;
        opacity: 0.8
    }
}

#ab-footer.black-theme button.invert {
    color: #fff;
    background-color: rgba(255,255,255,0.05);
    border-color: #fff
}

#ab-footer.black-theme .copyright-container {
    border-top-color: rgba(233,232,249,0.2)
}

#ab-footer.black-theme .copyright-container p {
    color: #c6c6c6
}

#ab-footer.black-theme .contribute-mobile {
    background: linear-gradient(264.11deg, #212121 -19.75%, #1a1919 36.64%, #1a1a1a 100%)
}

#ab-footer.black-theme .contribute-mobile p {
    color: #fff
}

.footerBottomText {
    font-size: 12px !important;
    min-width: 60%
}

@media only screen and (max-width: 575px) {
    .footerBottomText {
        margin-top:2.0625rem;
        padding: 0px 1.5625rem 1.8125rem;
        max-width: 64rem
    }
}

.followUsText {
    font-size: 14px !important
}

@media only screen and (max-width: 575px) {
    .followUsText {
        line-height:1.75rem;
        margin-bottom: .5625rem
    }
}

.socialLinkContainer {
    flex-shrink: 0;
    display: flex;
    align-items: center
}

@media only screen and (max-width: 575px) {
    .socialLinkContainer {
        flex-direction:column;
        justify-content: center;
        align-items: center
    }
}

.contentCtaWrapper {
    display: flex
}

.logoContentWrapper {
    min-width: 75%;
    margin-right: 5%;
    border-right: 1px solid #e9e8f9
}

@media only screen and (max-width: 575px) {
    .logoContentWrapper {
        border-right:none;
        margin-right: 0
    }
}

.contriText {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 1rem !important;
    display: block
}

.text-font-16 {
    font-size: 1rem !important
}

.body-small-important {
    font-size: .875rem !important
}

.width-100 {
    width: 100% !important
}

@media only screen and (min-width: 769px) {
    .width-100 {
        flex-wrap:nowrap !important
    }
}

.min-width-15 {
    min-width: 15% !important
}

#ab-footer .btn span {
    font-weight: 600
}

@media only screen and (min-width: 769px) {
    .min-width-18-6 {
        min-width:18.6% !important
    }
}

@media only screen and (min-width: 769px) {
    .min-width-31 {
        min-width:31% !important
    }
}

.min-width-16 {
    min-width: 16% !important
}

.black-theme .container .bold-title,.black-theme .container .info .logo-wrap .logo-text,.black-theme .ab_btn.invert span {
    color: #fff !important
}

@media only screen and (max-width: 768px) {
    .black-theme .container .contentCtaWrapper .logoContentWrapper {
        border:0px !important
    }
}

.footer_awards_section {
    display: flex;
    margin-bottom: 2.5625rem;
    margin-top: 1.25rem
}

@media only screen and (max-width: 575px) {
    .footer_awards_section {
        flex-direction:column;
        margin-bottom: 0px
    }
}

.footer_awards_section .awards_banner {
    width: 60%;
    height: 100%;
    background: #0a0a0a;
    border-radius: .5rem;
    text-align: center
}

@media only screen and (max-width: 575px) {
    .footer_awards_section .awards_banner {
        width:100%;
        margin-bottom: 1.25rem
    }
}

.footer_awards_section .awards_banner img {
    margin-top: 1.25rem;
    height: 8.75rem
}

@media only screen and (max-width: 575px) {
    .footer_awards_section .awards_banner img {
        width:100%
    }
}

.footer_awards_section .footer_stats {
    display: flex;
    flex-direction: column;
    margin: 0 5% 5% 5%
}

@media only screen and (max-width: 575px) {
    .footer_awards_section .footer_stats {
        margin-left:0
    }
}

.footer_awards_section .footer_stats .col {
    width: 100% !important
}

@media screen and (max-width: 376px) {
    .footer_awards_section .footer_stats .col .item:last-child {
        margin-top:8px
    }
}

.footer_awards_section .footer_stats p.title.semibold {
    margin-top: 0px !important;
    margin-left: 0px !important;
    width: 100%
}

.app-prom-banner {
    margin-bottom: 2rem;
    padding: 1.5rem 1rem;
    background: #eff1fe
}

.app-prom-banner--dark-bg {
    background: #171717
}

@media only screen and (max-width: 575px) {
    .app-prom-banner {
        padding:1.25rem 1rem;
        margin-bottom: 0
    }
}

.app-prom-banner__title {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2rem;
    color: #1E223C
}

.app-prom-banner__title--white {
    color: #fff
}

@media only screen and (max-width: 575px) {
    .app-prom-banner__title {
        font-size:1rem;
        line-height: 1.5rem;
        text-align: center;
        letter-spacing: 0.15px
    }
}

.app-prom-banner__link {
    width: fit-content;
    margin-top: 1rem
}

@media only screen and (max-width: 575px) {
    .app-prom-banner__link img {
        width:108px;
        height: 32px
    }
}

@media only screen and (max-width: 575px) {
    .app-prom-banner__description {
        align-items:center
    }
}

.app-prom-banner__qr {
    margin-left: 2rem;
    width: 126px;
    height: 126px;
    background: #fff;
    box-shadow: 0px 2px 6px -2px rgba(0,106,194,0.2);
    border-radius: 6px;
    flex-shrink: 0
}

@media only screen and (max-width: 575px) {
    .app-prom-banner__qr {
        display:none
    }
}

.ab_btn {
    padding: 7px 14px;
    min-width: 120px;
    cursor: pointer;
    box-shadow: 0 2px 6px -2px rgba(0,106,194,0.2);
    background-color: rgb(1, 80, 154);
    border: 1px solid rgb(1, 80, 154);
    color: #fff;
    border-radius: 1.5rem;
    outline: none
}

.ab_btn span {
    color: #fff !important
}

.ab_btn span.largeText {
    font-size: 1rem;
    font-weight: 600
}

@media only screen and (max-width: 575px) {
    .ab_btn span.largeText {
        font-size:.875rem;
        font-weight: bold
    }
}

.ab_btn:hover {
    background-color: #4158d4;
    border-color: #4158d4
}

.ab_btn:active {
    background-color: #2f48cd;
    border-color: #2f48cd
}

.ab_btn.round {
    border-radius: 24px
}

.ab_btn.textBtn {
    background-color: transparent;
    border-color: transparent;
    box-shadow: none
}

.ab_btn.textBtn span {
    color: rgb(1, 80, 154) !important
}

.ab_btn.invert {
    background-color: #fff;
    border-color: rgb(1, 80, 154);
    box-shadow: none
}

.ab_btn.invert span {
    color: rgb(1, 80, 154) !important
}

.ab_btn.invert:hover {
    border-color: #4158d4
}

.ab_btn.invert:hover span {
    color: #4158d4 !important
}

.ab_btn.invert.active {
    border-color: #2f48cd
}

.ab_btn.invert.active span {
    color: #2f48cd !important
}

.ab_btn:disabled {
    cursor: not-allowed
}